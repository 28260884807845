import { bool, func, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useEffect } from 'react';
import { proofFn } from '../utils/utils';

const getIcon = ({ expanded }) => (expanded ? faChevronDown : faChevronRight);

const ToggleButton = ({ expanded, onClick, styleOverrides }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [icon, setIcon] = useState(getIcon({ expanded }));

  useEffect(() => setIcon(getIcon({ expanded: isExpanded })), [isExpanded]);

  const clickHandlerFromProps = proofFn(onClick);
  const handleClick = () => {
    const newIsExpanded = !isExpanded;
    setIsExpanded(newIsExpanded);
    clickHandlerFromProps(newIsExpanded);
  };
  return (
    <div
      role='switch'
      className={`cursor-pointer select-none ${styleOverrides}`}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};

ToggleButton.propTypes = {
  expanded: bool,
  onClick: func,
  styleOverrides: string,
};

export default ToggleButton;
