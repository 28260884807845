import { createSlice } from '@reduxjs/toolkit';

const initialState = { names: [] };

export const taskNamesSlice = createSlice({
  name: 'taskNames',
  initialState,
  reducers: {
    addTaskName: (state, action) => {
      state.names.push(action.payload.name);
    },
    removeTaskName: (state, action) => {
      const index = action.payload;
      state.names.splice(index, 1);
    },
    clearTaskNames: () => initialState,
  },
});

export const { addTaskName, removeTaskName, clearTaskNames } =
  taskNamesSlice.actions;
export default taskNamesSlice.reducer;
