import { useState } from 'react';
import { useSelector } from 'react-redux';
import AddLabels from './AddLabels';

const useAddLabels = props => {
  const state = useState('');
  const labels = useSelector(state => state.addLabel.labels);
  const clearLabelInput = () => state[1]('');
  const getLabelsInputValue = () => state[0];
  const getLabelsList = () => labels;
  return {
    AddLabels: <AddLabels {...props} value={state} />,
    state,
    clearLabelInput,
    getLabelsInputValue,
    getLabelsList,
  };
};
export default useAddLabels;
