// import PropTypes from 'prop-types'
import { bool, string, any } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useEffect } from 'react';

const ToggleContainer = ({
  children,
  label,
  containerStylesOverrides,
  labeStylesOverrides,
  expanded = false,
}) => {
  const [displayTasks, setDisplayTasks] = useState(expanded);
  const [arrowIcon, setArrowIcon] = useState(faChevronRight);

  const handleClick = () => {
    setDisplayTasks(!displayTasks);
  };
  useEffect(() => {
    setArrowIcon(displayTasks ? faChevronDown : faChevronRight);
  }, [displayTasks]);

  return (
    <div className={`m-0 p-0 relative ${containerStylesOverrides}`}>
      <div
        className='text-left cursor-pointer select-none'
        onClick={handleClick}
      >
        <FontAwesomeIcon className={`w-5`} icon={arrowIcon} />
        <span
          className={`${labeStylesOverrides}
            ${displayTasks ? 'italic' : ''}`}
        >
          {label}
        </span>
      </div>
      <div
        className={`m-0 ml-2 sm:ml-5
        p-0 relative`}
      >
        {displayTasks && children}
      </div>
    </div>
  );
};

ToggleContainer.propTypes = {
  label: string,
  containerStylesOverrides: string,
  labeStylesOverrides: string,
  expanded: bool,
  children: any,
};

export default ToggleContainer;
