import { bool, string } from 'prop-types';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const Loading = ({ header, subHeader, showSpinner }) => {
  return (
    <div
      className={`bg-white/90 w-full h-full fixed top-0 left-0 z-50
    flex items-start sm:items-center justify-center`}
    >
      <div className='w-80 py-10 rounded-xl mt-8 sm:mt-0'>
        <div role='heading' aria-level='1' className='text-sky-600 text-lg'>
          {header || 'Loading'}
        </div>
        <div
          role='heading'
          aria-level='2'
          className='tracking-wider text-sky-600 text-sm font-medium'
        >
          {subHeader}
        </div>
        {showSpinner && <LoadingSpinner />}
      </div>
    </div>
  );
};

Loading.propTypes = {
  header: string,
  subHeader: string,
  showSpinner: bool,
};

export default Loading;
