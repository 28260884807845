import { array, bool, object, string, func } from 'prop-types';
import { useState } from 'react';

import TaskIcon from '../TaskIcon';
import ActionsPanel from '../ActionsPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationDot,
  faTag,
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';
import { proofFn } from '../utils/utils';

const getClassNames = ({ component, isComplete }) => {
  const baseClassName = `task-list-item relative grid grid-cols-6 my-2`;
  return baseClassName + (isComplete ? ' text-zinc-400 italic' : '');
};

const Task = props => {
  const {
    displayOnly = false,
    status: { completionTime, isComplete } = {},
    icon,
    title,
    location,
    labels,
    handleClick: handleClickFromProps,
    handleOnEdit,
    handleOnDelete,
  } = props;
  const [showActions, setShowActions] = useState(false);
  const actionsPanelOnClickHandler = e => {
    setShowActions(false);
    const handleClick = proofFn(handleClickFromProps);
    handleClick({ taskData: props });
  };
  const actionsPanelOnClickEditHandler = e => {
    proofFn(handleOnEdit)({ taskData: props });
    setShowActions(false);
  };

  const actionPanelOnClickDeleteHandler = e => {
    proofFn(handleOnDelete)({ taskData: props });
    setShowActions(false);
  };

  const actionsPanelOnMouseOutHandler = e => {
    setShowActions(false);
  };

  const getLocationsList = locations =>
    locations.map((location, index) => (
      <div
        key={index}
        className='text-left text-sm col-span-5 sm:mt-1 pr-3 inline'
      >
        {location} {index < locations.length - 1 ? ',' : ''}
      </div>
    ));

  return (
    <div className={getClassNames({ isComplete })}>
      <TaskIcon icon={icon} isComplete={isComplete} />
      {displayOnly && !title ? (
        <div
          data-testid='title-placeholder'
          className={`bg-zinc-100 border-zinc-200 border h-7 col-span-4
        animate-pulse`}
        />
      ) : (
        <div
          className='grow text-left text-lg font-medium
          col-span-4 tracking-wide'
        >
          {title}
        </div>
      )}

      {isComplete && (
        <div
          className='col-span-5 col-start-2 text-left font-bold mb-2
        py-1'
        >
          <span className='p-1 text-xs mr-2'>Completed:</span>
          {new Date(completionTime).toDateString()}
        </div>
      )}

      {displayOnly && !Boolean(location?.length) ? (
        <div
          data-testid='location-placeholder'
          className={`col-span-3 bg-zinc-100 
        border-zinc-200 border h-5 mt-1 animate-pulse`}
        />
      ) : (
        Boolean(location?.length) && (
          <div className='text-left text-sm sm:mt-1 col-span-5'>
            <div className='pr-2 items-center text-zinc-300 inline'>
              <FontAwesomeIcon icon={faLocationDot} />
            </div>
            {getLocationsList(location)}
          </div>
        )
      )}
      {Boolean(labels?.length) && (
        <div className='flex flex-wrap text-left col-start-2 col-span-5'>
          <div className='pr-2 flex items-center text-zinc-300'>
            <FontAwesomeIcon icon={faTag} />
          </div>
          {labels.map((label, key) => (
            <span
              key={key}
              className={`py-1 px-2 m-1 text-xs rounded-lg 
          bg-sky-100`}
            >
              {label}
            </span>
          ))}
        </div>
      )}
      {!displayOnly && showActions && (
        <ActionsPanel
          onClick={actionsPanelOnClickHandler}
          onClickEdit={actionsPanelOnClickEditHandler}
          onMouseOut={actionsPanelOnMouseOutHandler}
          onClickDelete={actionPanelOnClickDeleteHandler}
          isComplete={isComplete}
        />
      )}
      {!displayOnly && (
        <div className='col-start-6 row-start-1'>
          <button aria-label="show action panel"
            onClick={e => {
              setShowActions(true);
            }}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </button>
        </div>
      )}
    </div>
  );
};

Task.propTypes = {
  displayOnly: bool,
  categoryId: string,
  status: object,
  done: object,
  icon: string,
  title: string,
  labels: array,
  location: array,
  _id: string,
  handleClick: func,
  handleOnEdit: func,
  handleOnDelete: func,
};

export default Task;
