import { useSelector } from 'react-redux';

const UserBadge = () => {
  const { name, email, picture } = useSelector(state => state.user);
  return (
    <div className={`flex space-x-2 text-left p-7`}>
      <div className={``}>
        <img
          className='rounded-full'
          width={'40px'}
          height={'40px'}
          src={picture}
          alt='avatar'
          referrerPolicy='no-referrer'
        />
      </div>
      <div className='flex flex-col'>
        <div className={``}>{name}</div>
        <div className={`text-xs`}>{email}</div>
      </div>
    </div>
  );
};

export default UserBadge;
