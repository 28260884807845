import { callAPI, getPostOptions } from './util';

export const getTasksByCategory = async () => {
  return callAPI('/tasksByCategory');
};

export const getCategoryCatalog = async () => {
  return callAPI('/categoryCatalog');
};

export const createNewTask = async taskData => {
  return callAPI('/addTask', getPostOptions(taskData));
};

export const createNewCategory = async categoryData => {
  return callAPI('/newCategory', getPostOptions(categoryData));
};

export const markTaskAsOpen = async taskData => {
  return callAPI('/reopenTask', getPostOptions(taskData));
};

export const markTaskAsComplete = async taskData => {
  return callAPI('/markTaskAsDone', getPostOptions(taskData));
};

export const handleCredentialResponse = async googleData => {
  return callAPI(
    '/continue',
    getPostOptions({ oauthToken: googleData.credential }),
  );
};

export const logoutUser = async () => {
  return callAPI('/logout');
};

export const getCategoryData = async ({ categoryId }) => {
  return callAPI(`/categoryData/?categoryId=${categoryId}`);
};

export const updateDefaultView = async ({ categoryId, defaultView }) => {
  return callAPI(
    '/updateCategoryDefaultView',
    getPostOptions({ categoryId, defaultView }),
  );
};

export const updateTask = async taskData =>
  callAPI('/updateTask', { ...getPostOptions(taskData), method: 'PUT' });

export const deleteTask = async taskData =>
  callAPI('/deleteTask', { ...getPostOptions(taskData), method: 'DELETE' });

export const getUserLoginData = async () => callAPI('/userLogin');
