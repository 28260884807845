import { ToastContainer, toast } from 'react-toastify';
import GoogleLogin from '../GoogleLogin/GoogleLogin';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PageLevelLoading from '../PageLevelLoading/PageLevelLoading';
import useLoginHandlers from './useLoginHandlers';

export const Login = () => {
  const mainContainerStyles = `flex h-screen items-center w-72 mx-auto`;
  const message = useSelector(state => state.messages.loginMessage);
  const { googleOnLogin } = useLoginHandlers();

  useEffect(() => {
    if (message) {
      toast(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        closeButton: false,
      });
    }
  }, [message]);

  return (
    <div className='text-center'>
      <PageLevelLoading />
      <div className={mainContainerStyles}>
        <div className='h-fit p-5 rounded-lg shadow-xl bg-white'>
          <div className='font-bold mb-3 text-left mb-5'>
            <span className=' text-zinc-400'>Login</span>
          </div>
          <GoogleLogin onLogin={googleOnLogin} />
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};
