import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import TaskIconSelector from '../../components/TaskIconSelector';
import TaskCategory from '../../components/TaskCategory/TaskCategory';
import {
  DEFAULT_ICON_NAME,
  iconNames,
} from '../../components/constants/iconMap';
import { createNewTask, getCategoryCatalog } from '../../components/api/api';
import { displayPageError } from '../../components/utils/utils';
import { clearLabels } from '../../components/store/addLabelsSlice';
import { clearTaskNames } from '../../components/store/taskNamesSlice';
import { clearLocations } from '../../components/store/taskLocationsSlice';
// eslint-disable-next-line max-len
import useMultiTaskInput from '../../components/MultiTaskInput/useMultiTaskInput';
// eslint-disable-next-line max-len
import useMultiLocationInput from '../../components/MultiLocationInput/useMultiLocationInput';
import useAddLabels from '../../components/AddLabels/useAddLabels';
import useNavContext from '../../hooks/useNavContext';

const AddTask = () => {
  const [categoryData, setCategoryData] = useState({});
  const [selectedOption, setSelectedOption] = useState('-1');
  const [lastTaskLocation, setLastTaskLocation] = useState([]);
  const [taskIcon, setTaskIcon] = useState('');
  // TODO: use app state instead of internal state for `categories`
  const [categories, setCategories] = useState({});
  const [lastTaskName, setLastTaskName] = useState('');
  const navigate = useNavigate();
  const taskNameList = useSelector(state => state.taskNames.names);
  const taskLocationList = useSelector(state => state.taskLocations.locations);
  const labels = useSelector(state => state.addLabel.labels);
  const dispatch = useDispatch();
  const { setNavHeader } = useContext(useNavContext());

  useEffect(() => {
    setNavHeader({ header: 'Add Tasks' });
    resetInputFields();
    getCategoryCatalog()
      .then(categoryCatalog => {
        const newCategories = {};
        for (const { 1: categoryData } of Object.entries(categoryCatalog)) {
          newCategories[categoryData._id] = categoryData;
        }
        setCategories(newCategories);
      })
      .catch(error => {
        displayPageError(error);
      });
  }, []);

  const onChangehandler = e => {
    const { id, value } = e.target;
    switch (id) {
      case 'category-list':
        setSelectedOption(value);
        setCategoryData(categories[value]);
        break;
    }
  };

  const resetInputFields = () => {
    setSelectedOption('-1');
    setTaskIcon('');
    setLastTaskName('');
    setLastTaskLocation([]);
    dispatch(clearLabels());
    dispatch(clearTaskNames());
    dispatch(clearLocations());
    clearMultiTaskInput();
    clearMultiLocationInput();
    clearLabelInput();
  };

  const mainContainerClassName = `mx-4
  sm:w-3/4 sm:mt-8 sm:mx-auto sm:shadow-xl sm:rounded-lg sm:bg-white sm:p-5`;
  const borderClassName = `border border-zinc-400`;
  const fieldContainerClassName = `pb-2 mb-3`;
  const labelClassName = `inline-block w-full text-sm
    text-left text-zinc-400 uppercase`;
  const inputClassName = `${borderClassName} mt-3 pb-3 pl-2 w-full border-0
    border-b-2 focus:border-lime-600 focus:outline-none bg-inherit`;
  const submitClassName = `border-2 rounded-md border-emerald-400 w-full py-3
  text-white bg-emerald-400 font-bold uppercase shadow-md
  shadow-emerald-400/20`;

  const taskProps = {
    _id: categoryData._id,
    displayOnly: true,
    title: categoryData.title,
    description: categoryData.description,
    tasks: {
      displayOnlyTask: {
        _id: 'displayOnlyTask',
        icon: taskIcon,
        location: lastTaskLocation,
        title: lastTaskName,
      },
    },
  };

  const getCategoryListOptions = () => {
    const categoryOptions = [];
    for (const { 1: category } of Object.entries(categories)) {
      const { _id, title } = category;
      categoryOptions.push(
        <option className='uppercase' key={_id} value={_id}>
          {title}
        </option>,
      );
    }
    return categoryOptions;
  };

  const { MultiTaskInput, clearMultiTaskInput, getMultiTaskInputValue } =
    useMultiTaskInput({
      inputClassName,
      labelClassName,
      onChange: e => setLastTaskName(e.target.value),
    });

  const {
    MultiLocationInput,
    clearMultiLocationInput,
    getMultiLocationInputValue,
  } = useMultiLocationInput({
    inputClassName,
    labelClassName,
    onChange: e => {
      const index = taskLocationList.length;
      const newLastLocationList = [...taskLocationList];
      newLastLocationList[index] = e.target.value;
      setLastTaskLocation(newLastLocationList);
    },
    onAdd: addedLocation => {
      setLastTaskLocation([...taskLocationList, addedLocation]);
    },
  });

  const { AddLabels, clearLabelInput, getLabelsInputValue } = useAddLabels({
    inputClassName,
    labelClassName,
  });

  return (
    <>
      <div className={mainContainerClassName}>
        <form
          onSubmit={e => {
            e.preventDefault();
            const newTask = {
              icon: taskIcon || DEFAULT_ICON_NAME,
              title: getMultiTaskInputValue().trim(),
              titleList: taskNameList,
              location: getMultiLocationInputValue().trim(),
              locationList: taskLocationList,
              categoryId: categoryData._id,
              label: getLabelsInputValue().trim(),
              labels,
            };

            createNewTask(newTask)
              .then(response => {
                const { status, message } = response;
                if (status === 'ERROR') {
                  displayPageError(response);
                }
                resetInputFields();
                const SuccessMessage = () => (
                  <div>
                    <div>{message}</div>
                    <button
                      className='mx-auto'
                      onClick={e => {
                        navigate('/allTasks');
                      }}
                    >
                      <span
                        className={`uppercase text-xs text-blue-500 
                    font-bold`}
                      >
                        go to all tasks
                      </span>
                    </button>
                  </div>
                );
                toast.success(<SuccessMessage />);
              })
              .catch(error => {
                displayPageError(error);
              });
          }}
        >
          <div className={fieldContainerClassName}>
            <label className={labelClassName} htmlFor='category-list'>
              Category
            </label>
            <select
              id='category-list'
              value={selectedOption}
              onChange={onChangehandler}
              className={inputClassName}
            >
              <option className='uppercase' value='-1' disabled>
                select a category
              </option>
              {getCategoryListOptions()}
            </select>
          </div>
          <div className={fieldContainerClassName}>{MultiTaskInput}</div>
          <div className={fieldContainerClassName}>{MultiLocationInput}</div>
          <div className={fieldContainerClassName}>
            <label className={labelClassName}>Icon</label>
            <TaskIconSelector
              value={taskIcon}
              icons={iconNames}
              onSelected={({ iconName }) => {
                setTaskIcon(iconName);
              }}
            />
          </div>
          <div className={fieldContainerClassName}>{AddLabels}</div>
          <div className='sm:py-5 sm:px-8 px-4 my-5 flex justify-center'>
            <TaskCategory className='w-full' {...taskProps} />
          </div>
          <div className='py-5'>
            <input
              className={submitClassName}
              type='submit'
              value='add task'
            ></input>
          </div>
        </form>
      </div>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AddTask;
