import './styles.css';

const LoadingSpinner = props => {
  return (
    <div
      role='progressbar'
      className='lds-ripple flex items-center justify-center'
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

LoadingSpinner.propTypes = {};

export default LoadingSpinner;
