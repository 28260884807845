import { useEffect, useState } from 'react';
import { array, bool, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import Divider from '../Divider/Divider';

const TaskContainer = ({ tasks = [], label, showCount }) => {
  if (tasks.length === 0) {
    return;
  }
  const [displayTasks, setDisplayTasks] = useState(false);
  const [arrowIcon, setArrowIcon] = useState(faChevronRight);

  const handleClick = e => {
    setDisplayTasks(!displayTasks);
  };
  useEffect(() => {
    setArrowIcon(displayTasks ? faChevronDown : faChevronRight);
  }, [displayTasks]);

  return (
    <div className={`m-0 mt-5 mb-5 p-0 relative`}>
      <div
        className={`text-left cursor-pointer select-none`}
        onClick={handleClick}
      >
        <FontAwesomeIcon className={`w-5`} icon={arrowIcon} />
        <span
          className={`text-xs uppercase pl-3
          ${displayTasks ? 'italic' : ''}`}
        >
          {label} {showCount && `(${tasks.length})`}
        </span>
      </div>
      <div
        className={`m-0 mx-2 sm:mx-5
      p-0 relative`}
      >
        {displayTasks &&
          tasks.map((task, index) => (
            <div key={index}>
              {task}
              <Divider />
            </div>
          ))}
      </div>
    </div>
  );
};

TaskContainer.propTypes = {
  tasks: array,
  label: string,
  showCount: bool,
};

export default TaskContainer;
