import { bool, string, object, func } from 'prop-types';
import TaskContainer from '../CollapsibleTaskContainer/TaskContainer';
import Divider from '../Divider/Divider';
import Task from '../Task/Task';
import { getFilteredTasks } from './utils';

const TaskList = ({
  displayOnly,
  categoryId,
  tasks,
  handleClick,
  handleOnEdit,
  handleOnDelete,
}) => {
  const { completedTasks, openTasks } = getFilteredTasks({
    component: Task,
    displayOnly,
    categoryId,
    tasks,
    handleClick,
    handleOnEdit,
    handleOnDelete,
  });

  const hasCompletedTasks = Boolean(completedTasks.length);
  const showDivider = openTasks.length > 1;

  return (
    <>
      {openTasks.map((task, index) => (
        <div key={index}>
          {task}
          {showDivider && <Divider />}
        </div>
      ))}
      {hasCompletedTasks && (
        <TaskContainer
          tasks={completedTasks}
          label='completed'
          showCount={true}
        />
      )}
    </>
  );
};

TaskList.propTypes = {
  categoryId: string,
  displayOnly: bool,
  tasks: object,
  handleClick: func,
  handleOnEdit: func,
  handleOnDelete: func,
};

export default TaskList;
