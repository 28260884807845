import { array, string, func } from 'prop-types';

import TaskIconSelector from '../TaskIconSelector';
import { proofFn } from '../utils/utils';

const IconPicker = ({ value, labelClassName, icons, onSelected }) => {
  const [taskIcon, setTaskIcon] = value;
  return (
    <>
      <label className={labelClassName}>Icon</label>
      <TaskIconSelector
        value={taskIcon}
        icons={icons}
        onSelected={({ iconName }) => {
          proofFn(onSelected)({ iconName });
          setTaskIcon(iconName);
        }}
      />
    </>
  );
};

IconPicker.propTypes = {
  labelClassName: string,
  value: array,
  icons: array,
  onSelected: func,
};

export default IconPicker;
