import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { removePageLevelError } from '../store/errorMessagesSlice';

const PageLevelError = () => {
  const errorDetails = useSelector(
    state => state?.errorMessages?.pageLevelError,
  );
  const dispatch = useDispatch();

  return (
    <ErrorMessage
      message={errorDetails?.message}
      onClick={e => {
        dispatch(removePageLevelError());
      }}
    />
  );
};

export default PageLevelError;
