/*
TODO this handlers are not working when invoked from a filtered view
because are pointing to the wrong part of the state (i.e. `tasks`)

the error can be disguised when coming from `AllTasks` into `CategoryView`
cuz at that point there is data in the `tasks` slice of the store
 */
import { markTaskAsComplete, markTaskAsOpen } from '../api/api';
import store from '../store/store';
import { markTaskAsDone, reopenTask } from '../store/tasksSlice';

export const handleClick = ({ taskData }) => {
  const { status: { isComplete } = {}, _id: taskId } = taskData;
  if (isComplete) {
    markTaskAsOpen({ taskId }).then(updatedTaskData => {
      store.dispatch(reopenTask(updatedTaskData));
    });
  } else {
    markTaskAsComplete({ taskId }).then(updatedTaskData => {
      store.dispatch(markTaskAsDone(updatedTaskData));
    });
  }
};
