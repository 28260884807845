import { getClickHandler } from './handlers';

export const getTasksData = (tasks = {}) => {
  const taskComponents = [];
  const taskIdList = Object.keys(tasks);
  for (const taskId of taskIdList) {
    taskComponents.push(tasks[taskId]);
  }
  return taskComponents;
};

export const findAndRenderTasks = ({
  categories,
  categoryId,
  containerComponent,
  containerStyleOverrides,
  tasksContainer,
  filterPath = [],
  handleClick: clickHandlerFromProps,
  handleOnEdit,
  handleOnTaskUpdate,
}) => {
  const CategoryContainer = containerComponent;
  let renderedTaskSections = [];
  const currentCategory = categories[categoryId];
  const { title, tasks, ...filteredSubCategories } = currentCategory;
  filterPath.push(categoryId);

  if (tasks) {
    const TaskContainer = tasksContainer;
    const path = [...filterPath, 'tasks'];
    /*
    you know that all these `tasks` are gonna be stored under
    this `path`
    can create a dictionary that keeps track of what paths is each
    task._id in
    {
      taskId:[path1, path2, path3, ...]
    }

    so that when a task is closed/reopened, all these other paths
    get also refreshed

    This will require a new local storage block, call it `metadata`
     */
    renderedTaskSections = [
      <div className='all-found-tasks relative' key={categoryId}>
        <TaskContainer
          className='tasks-container-parent'
          tasks={tasks}
          handleClick={getClickHandler({ clickHandlerFromProps, path })}
          handleOnEdit={handleOnEdit}
          handleOnTaskUpdate={handleOnTaskUpdate}
        />
      </div>,
    ];
  } else {
    const subCatIdList = Object.keys(filteredSubCategories);
    for (const subCatId of subCatIdList) {
      renderedTaskSections.push(
        ...findAndRenderTasks({
          categories: filteredSubCategories,
          categoryId: subCatId,
          containerComponent: CategoryContainer,
          containerStyleOverrides,
          tasksContainer,
          filterPath,
          handleClick: clickHandlerFromProps,
          handleOnEdit: handleOnEdit,
          handleOnTaskUpdate: handleOnTaskUpdate,
        }),
      );
    }
  }
  filterPath.pop();

  const renderedBlock = (
    <CategoryContainer
      label={title}
      {...containerStyleOverrides}
      expanded={true}
      key={categoryId}
    >
      {renderedTaskSections}
    </CategoryContainer>
  );
  return [renderedBlock];
};

export const fetchFilteredTasks = ({
  categories,
  containerComponent,
  containerStyleOverrides,
  tasksContainer,
  handleClick,
  handleOnEdit,
  handleOnTaskUpdate,
}) => {
  const filteredCategories = [];
  const categoryIdList = Object.keys(categories);
  for (const categoryId of categoryIdList) {
    filteredCategories.push(
      findAndRenderTasks({
        categories,
        categoryId,
        containerComponent,
        containerStyleOverrides,
        tasksContainer,
        handleClick,
        handleOnEdit,
        handleOnTaskUpdate,
      }),
    );
  }

  return filteredCategories;
};
