import { func, string, array } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addTaskName, removeTaskName } from '../store/taskNamesSlice';
import { proofFn } from '../utils/utils';

const MultiTaskInput = ({
  inputClassName,
  labelClassName,
  listClassName,
  onChange,
  value,
}) => {
  const dispatch = useDispatch();
  const [taskName, setTaskName] = value;
  const taskNameList = useSelector(state => state.taskNames.names);
  const propsOnChange = proofFn(onChange);
  const onChangehandler = e => {
    setTaskName(e.target.value);
    propsOnChange(e);
  };
  return (
    <>
      <label className={labelClassName} htmlFor='task-name-no-autofill'>
        Task Name
      </label>
      <input
        id='task-name-no-autofill'
        value={taskName}
        onChange={onChangehandler}
        className={inputClassName}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (!taskName) {
              return;
            }
            dispatch(addTaskName({ name: taskName.trim() }));
            setTaskName('');
          }
        }}
      ></input>
      <div
        role='list'
        className={`flex flex-wrap mt-2 text-sm ${listClassName}`}
      >
        {taskNameList.map((taskName, index) => (
          <div
            role='listitem'
            key={index}
            className='border border-emerald-400 p-0 flex m-1
                  rounded-sm pr-3 cursor-pointer'
            onClick={e => dispatch(removeTaskName(index))}
          >
            <div
              className='rounded-l-sm m-0 p-0 inline
                      border-emerald-400 bg-emerald-400 text-white pt-1'
            >
              <span className='pl-1 pr-3'>{index + 1}</span>
            </div>
            <div
              className='m-0 py-1 pl-1 inline text-sm
                  hover:line-through hover:italic'
            >
              {taskName}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

MultiTaskInput.propTypes = {
  inputClassName: string,
  labelClassName: string,
  listClassName: string,
  onChange: func,
  value: array,
};

export default MultiTaskInput;
