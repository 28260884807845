import { getCategoryData, getTasksByCategory } from '../../components/api/api';
import store from '../../components/store/store';
import { addTaskCategory } from '../../components/store/tasksSlice';
import { displayPageError } from '../../components/utils/utils';
import { handleCategoryResponse } from './dataHandlers';
import { setLabels } from '../../components/store/addLabelsSlice';
import { setIcon } from '../../components/store/taskIconSlice';
import { setLocations } from '../../components/store/taskLocationsSlice';
import { setTaskName } from '../../components/store/taskNameSlice';

// TODO this utils should be used in the AllTasks component
export const loadTasksByCategory = () =>
  getTasksByCategory()
    .then(handleCategoryResponse)
    .catch(error => displayPageError(error));

export const updateCategoryTasks = ({ categoryId }) =>
  getCategoryData({ categoryId }).then(categoryData => {
    const { categories } = categoryData;
    store.dispatch(addTaskCategory(categories[categoryId]));
  });

export const initEditTaskModal = ({ taskData }) => {
  store.dispatch(setTaskName(taskData.title));
  store.dispatch(setLocations(taskData.location));
  store.dispatch(setIcon(taskData.icon));
  store.dispatch(setLabels(taskData.labels));
};
