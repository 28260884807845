import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useNavContext from '../../../hooks/useNavContext';
import {
  mainContainerClassName,
  categoryContainerClassName,
} from '../../../styles/categoryStyles';
// eslint-disable-next-line max-len
import { clearSingleCategoryViewData } from '../../store/singleCategoryViewSlice';
import SideMenu from '../../TaskCategory/SideMenu';
import {
  fetchLatestCategoryData,
  getClickHandler,
  markTaskForDeletion,
} from './handlers';
import {
  getMenuOptions,
  getTasksContainerByView,
  getViewComponent,
} from './util';

const CategoryView = () => {
  const { categoryId } = useParams();
  const categories = useSelector(state => state.singleCategoryView);
  const dispatch = useDispatch();
  const { setNavHeader } = useContext(useNavContext());

  const { initialCategoryData, filteredCategoryData, isFiltered, filterList } =
    categories;
  const { title, description, ...unfilteredCategoryData } =
    initialCategoryData[categoryId] || {};
  const categoryData = isFiltered ?
    { categories: filteredCategoryData } :
    unfilteredCategoryData;
  const currentViewMode = unfilteredCategoryData['default_view'];

  useEffect(() => {
    dispatch(clearSingleCategoryViewData());
    fetchLatestCategoryData({ categoryId, isFiltered, filterList });
  }, []);

  useEffect(() => {
    setNavHeader({
      header: title,
      subHeader: description,
      style: currentViewMode,
    });
  }, [initialCategoryData]);

  // TODO this can't be tested (see CategoryView.test.js)
  // but also because of the way the state is setup this
  // scenario is not lately to happen either
  // if (Object.keys(categoryData).length === 0) {
  //   return;
  // }

  const menuOptions = getMenuOptions({
    categoryId,
    currentViewMode,
    isFiltered,
  });
  const ViewComponent = getViewComponent({ currentViewMode, isFiltered });
  const tasksContainer = getTasksContainerByView({ currentViewMode });

  const handleOnTaskUpdate = async updatedTaskData => {
    await fetchLatestCategoryData({ categoryId, isFiltered, filterList });
  };

  const handleTaskDeletion = async ({ taskData }) => {
    await markTaskForDeletion(taskData);
    fetchLatestCategoryData({ categoryId, isFiltered, filterList });
  };

  return (
    <div className={mainContainerClassName + ` relative`}>
      <SideMenu menuOptions={menuOptions} />
      <ViewComponent
        className={categoryContainerClassName}
        {...categoryData}
        handleClick={getClickHandler({
          categoryId,
          isFiltered,
          filterList,
        })}
        handleOnDelete={handleTaskDeletion}
        handleOnTaskUpdate={handleOnTaskUpdate}
        tasksContainer={tasksContainer}
      />
    </div>
  );
};

export default CategoryView;
