import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useNavComponent from './hooks/useNavComponent';
import useNavContext from './hooks/useNavContext';
import useResetStore from './hooks/useResetStore';
// eslint-disable-next-line max-len
import PageLevelMessageContainer from './components/PageLevelMessageContainer/PageLevelMessageContainer';
import PageLevelError from './components/PageLevelError/PageLevelError';
import PageLevelLoading from './components/PageLevelLoading/PageLevelLoading';
import { APP_STATE } from './components/store/const';
import { addLoginMessage } from './components/store/messagesSlice';
import { getUserLoginData } from './components/api/api';
import { storeUser } from './components/store/userSlice';
import { isEmpty } from './components/utils/utils';

const App = () => {
  const NavContext = useNavContext();
  const { Nav, clearNavHeader, setNavHeader } = useNavComponent();
  const resetAppState = useResetStore();
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useSelector(state => state.appState);
  const sessionExpired = state === APP_STATE.sessionExpired;

  useEffect(() => {
    if (sessionExpired) {
      const message = 'Your session has expired. Please login again';
      dispatch(addLoginMessage({ message }));
      navigate('/login');
    }
  }, [sessionExpired]);

  const userData = useSelector(state => state.user);

  useEffect(() => {
    if (isEmpty(userData)) {
      getUserLoginData().then(userData => {
        dispatch(storeUser(userData));
      });
    }
  }, [userData]);

  useEffect(() => {
    if (location.pathname === '/') {
      resetAppState();
      clearNavHeader();
    }
  });

  return (
    <div className='App'>
      <PageLevelLoading />
      <Nav />
      <PageLevelError />
      <PageLevelMessageContainer />
      <NavContext.Provider value={{ clearNavHeader, setNavHeader }}>
        <Outlet />
      </NavContext.Provider>
    </div>
  );
};

export default App;
