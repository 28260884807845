import Filter from '../../../lib/Filter';
import {
  deleteTask,
  getCategoryData,
  markTaskAsComplete,
  markTaskAsOpen,
  updateDefaultView,
} from '../../api/api';
import {
  saveInitialCategoryData,
  saveDefaultView,
  saveFilteredCategoryData,
  clearFilteredCategoryData,
} from '../../store/singleCategoryViewSlice';
import store from '../../store/store';
import { displayPageError } from '../../utils/utils';

export const handleSingleCategoryResponse = ({ categories }) => {
  for (const categoryId of Object.keys(categories)) {
    store.dispatch(saveInitialCategoryData(categories[categoryId]));
  }
};

export const handleDefaultViewResponse = payload => {
  if (!payload?.success) {
    throw new Error('There was an eror updating your default view');
  }
  store.dispatch(saveDefaultView(payload));
};

export const updateTaskStatus = async categoryData => {
  const { taskData, categoryId, isFiltered, filterList } = categoryData;
  const { status: { isComplete } = {}, _id: taskId } = taskData;
  let updateTask = markTaskAsComplete;

  if (isComplete) {
    updateTask = markTaskAsOpen;
  }

  const updatedTaskData = await updateTask({ taskId });
  return { updatedTaskData, categoryId, isFiltered, filterList };
};

export const getDefaultViewHandler = (categoryId, newViewMode) => async () => {
  await updateDefaultView({
    categoryId,
    defaultView: newViewMode,
  })
    .then(handleDefaultViewResponse)
    .catch(error => {
      displayPageError(error);
    });
};

const handleFiltering = filterType => {
  // TODO find a way of not doing this check here
  // and just receiving the data that needs to be processed
  const { initialCategoryData, filteredCategoryData, isFiltered } =
    store.getState().singleCategoryView;
  const categories = isFiltered ? filteredCategoryData : initialCategoryData;

  const filter = new Filter();
  const filteredTasks = filter[filterType](categories);
  store.dispatch(
    saveFilteredCategoryData({ filteredTasks, filter: filterType }),
  );
  return filteredTasks;
};

export const filterByLabelsHandler = () => {
  return handleFiltering(Filter.FILTER_BY_LABELS);
};

export const filterByLocationHandler = () => {
  return handleFiltering(Filter.FILTER_BY_LOCATION);
};

export const clearAllFiltersHandler = () => {
  store.dispatch(clearFilteredCategoryData());
};

export const updateFilteredTasksHandler = async ({
  categories,
  filterList,
}) => {
  const filter = new Filter();
  let filteredTasks = categories;
  for (const filterType of filterList) {
    filteredTasks = filter[filterType](filteredTasks);
  }

  store.dispatch(saveFilteredCategoryData({ filteredTasks }));
};

export const fetchLatestCategoryData = async categoryData => {
  const { updatedTaskData, categoryId, isFiltered, filterList } = categoryData;
  // TODO return this instead of updatedTaskData
  await getCategoryData({ categoryId })
    .then(({ categories }) => {
      if (isFiltered) {
        updateFilteredTasksHandler({ categories, filterList });
      }
      handleSingleCategoryResponse({ categories });
    })
    .catch(error => displayPageError(error));

  return updatedTaskData;
};

export const getClickHandler = ({ categoryId, isFiltered, filterList }) => {
  return async ({ taskData }) =>
    updateTaskStatus({
      taskData,
      categoryId,
      isFiltered,
      filterList,
    }).then(fetchLatestCategoryData);
};

export const markTaskForDeletion = async taskData => deleteTask(taskData);
