import { createSlice } from '@reduxjs/toolkit';

const initialState = { categories: [] };
export const taskCategoriesSlice = createSlice({
  name: 'taskCategories',
  initialState,
  reducers: {
    addCategory: (state, action) => {
      state.categories.push(action.payload);
    },
    setCategories: (state, action) => {
      state.categories = action.payload ?? [];
    },
    removeCategory: (state, action) => {
      state.categories.splice(action.payload, 1);
    },
    clearCategories: () => initialState,
  },
});

export const { addCategory, setCategories, removeCategory, clearCategories } =
  taskCategoriesSlice.actions;
export default taskCategoriesSlice.reducer;
