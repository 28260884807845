import { object } from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewTaskHandler,
  handleChange,
} from '../../routes/addTask/handlers';
import { DEFAULT_ICON_NAME, iconNames } from '../constants/iconMap';
import Inline from '../CTAs/Inline/Inline';
import TaskIconSelector from '../TaskIconSelector';
import { displayPageError } from '../utils/utils';
// import AddLabels from '../AddLabels/AddLabels';
import { clearLabels } from '../store/addLabelsSlice';
import { addPageLevelMessage } from '../store/messagesSlice';
import { TYPES } from '../PageLevelMessage/PageMessage';
import useAddLabels from '../AddLabels/useAddLabels';

const AddTaskInLine = ({ categoryData }) => {
  const [taskName, setTaskName] = useState('');
  const [taskLocation, setTaskLocation] = useState('');
  const [displayIcon, setDisplayIcon] = useState(false);
  const [displayLabels, setDisplayLabels] = useState(false);
  const [taskIcon, setTaskIcon] = useState(DEFAULT_ICON_NAME);
  const dispatch = useDispatch();
  const labels = useSelector(state => state.addLabel.labels);
  const resetInputFields = () => {
    setTaskName('');
    setTaskLocation('');
    setTaskIcon(DEFAULT_ICON_NAME);
    dispatch(clearLabels());
    clearLabelInput('');
  };
  const handler = e => {
    const taskData = {
      icon: taskIcon,
      title: taskName,
      location: taskLocation,
      categoryId: categoryData._id,
      labels,
    };
    resetInputFields();
    createNewTaskHandler({ taskData })
      .then(() => {
        dispatch(
          addPageLevelMessage({
            message: {
              text: 'task added successfully',
              type: TYPES.SUCCESS,
              duration: 3000,
            },
          }),
        );
      })
      .catch(e => {
        displayPageError(e);
      });
  };

  // abstract and reuse
  const mainContainerClassName = `mx-1 sm:mt-0 sm:mx-6`;
  const borderClassName = `border border-zinc-400`;
  const fieldContainerClassName = `flex items-center`;
  const labelClassName = `inline-block w-1/3 text-sm
    text-left text-zinc-400 uppercase sm:w-1/4`;
  const inputClassName = `${borderClassName}  pl-2 w-full border-0
    border-b-2 focus:border-lime-600 focus:outline-none bg-inherit`;

  const { AddLabels, clearLabelInput } = useAddLabels({
    inputClassName: inputClassName + ' col-span-8',
    labelClassName: labelClassName + ' col-span-2',
    listClassName: 'col-span-10',
  });

  return (
    <div className={mainContainerClassName}>
      <div className={fieldContainerClassName}>
        <label className={labelClassName} htmlFor='task-name-no-autofill'>
          Task Name
        </label>
        <input
          id='task-name-no-autofill'
          value={taskName}
          onChange={e => handleChange(e, setTaskName)}
          className={inputClassName}
        ></input>
      </div>
      <div className={fieldContainerClassName}>
        <label className={labelClassName} htmlFor='task-location-no-autofill'>
          Location
        </label>
        <input
          id='task-location-no-autofill'
          value={taskLocation}
          onChange={e => handleChange(e, setTaskLocation)}
          className={inputClassName}
        ></input>
      </div>
      <div className='flex justify-end pt-2 mt-2'>
        <Inline
          text='add icon'
          showToggleArrow={true}
          handler={() => {
            setTaskIcon(DEFAULT_ICON_NAME);
            setDisplayIcon(!displayIcon);
          }}
        />
      </div>
      {displayIcon && (
        <div className={fieldContainerClassName + ' mt-3 '}>
          <label className={labelClassName}>Icon</label>
          <TaskIconSelector
            value={taskIcon}
            icons={iconNames}
            onSelected={({ iconName }) => {
              setTaskIcon(iconName);
            }}
            styleOverrides='w-full'
          />
        </div>
      )}
      <div className={`flex justify-end pt-2 mt-2`}>
        <Inline
          text='add labels'
          showToggleArrow={true}
          handler={() => setDisplayLabels(!displayLabels)}
        />
      </div>
      {displayLabels && (
        <div className={fieldContainerClassName + ' mt-3 grid grid-cols-10'}>
          {/* <AddLabels
            inputClassName={inputClassName + ' col-span-8'}
            labelClassName={labelClassName + ' col-span-2'}
            listClassName={'col-span-10'}
          /> */}
          {AddLabels}
        </div>
      )}
      <div className=' '>
        <Inline
          text='add a new task'
          styleOverrides='border-2 rounded-md border-emerald-400 text-sm
          my-4 py-3 text-white bg-emerald-400 shadow-md shadow-emerald-400/20'
          handler={handler}
        />
      </div>
    </div>
  );
};

AddTaskInLine.propTypes = { categoryData: object };
export default AddTaskInLine;
