import { useState } from 'react';
import { useSelector } from 'react-redux';
import MultiTaskInput from './MultiTaskInput';

const useMultiTaskInput = props => {
  const state = useState('');
  const taskNameList = useSelector(state => state.taskNames.names);

  const clearMultiTaskInput = () => state[1]('');
  const getMultiTaskInputValue = () => state[0];
  const getTaskNameList = () => taskNameList;
  return {
    MultiTaskInput: <MultiTaskInput {...props} value={state} />,
    clearMultiTaskInput,
    getMultiTaskInputValue,
    getTaskNameList,
  };
};

export default useMultiTaskInput;
