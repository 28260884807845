import { createSlice } from '@reduxjs/toolkit';
import { assign } from './utils/utils';

const initialState = {
  initialCategoryData: {},
  filteredCategoryData: {},
  isFiltered: false,
  filterList: [],
};

export const singleCategoryViewSlice = createSlice({
  name: 'singleCategoryView',
  initialState,
  reducers: {
    addTask: (state, action) => {
      const { payload: taskList } = action;
      taskList.forEach(task => {
        state.initialCategoryData[task.category_id] = state.initialCategoryData[
          task.category_id
        ] || { tasks: {} };
        state.initialCategoryData[task.category_id].tasks[task._id] = task;
      });
    },
    addCategoryViewData: (state, action) => {
      const { payload: category } = action;
      state.initialCategoryData[category._id] = category;
    },
    markTaskAsDone: (state, action) => {
      const { category_id: categoryId, _id: taskId } = action.payload;
      state.initialCategoryData[categoryId].tasks[taskId] = action.payload;
    },
    reopenTask: (state, action) => {
      const { category_id: categoryId, _id: taskId } = action.payload;
      state.initialCategoryData[categoryId].tasks[taskId] = action.payload;
    },
    markFilteredTaskAsDone: (state, action) => {
      const { taskData, path } = action.payload;
      assign(state.filteredCategoryData, path, taskData);
    },
    reopenFilteredTask: (state, action) => {
      const { taskData, path } = action.payload;
      assign(state.filteredCategoryData, path, taskData);
    },
    saveDefaultView: (state, action) => {
      const { _id: categoryId, default_view: defaultView } = action.payload;
      state.initialCategoryData[categoryId]['default_view'] = defaultView;
    },
    saveInitialCategoryData: (state, action) => {
      const { payload: category } = action;
      state.initialCategoryData[category._id] = category;
    },
    saveFilteredCategoryData: (state, action) => {
      const { filteredTasks, filter } = action.payload;
      state.filteredCategoryData = filteredTasks;
      if (filter) {
        state.filterList.push(filter);
        state.isFiltered = true;
      }
    },
    clearFilteredCategoryData: (state, action) => {
      state.filteredCategoryData = {};
      state.filterList = [];
      state.isFiltered = false;
    },
    clearSingleCategoryViewData: () => initialState,
  },
});

export const {
  addTask,
  addCategoryViewData,
  markTaskAsDone,
  reopenTask,
  saveDefaultView,
  clearSingleCategoryViewData,
  saveInitialCategoryData,
  saveFilteredCategoryData,
  clearFilteredCategoryData,
  markFilteredTaskAsDone,
  reopenFilteredTask,
} = singleCategoryViewSlice.actions;
export default singleCategoryViewSlice.reducer;
