import { object, string } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import Logout from './Logout';
import UserBadge from './UserBadge';
import { clearAppHeader } from '../utils/utils';
import { HEADER_STYLES, SUB_HEADER_STYLES } from './styles';

export const Nav = ({ headers, style }) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const { header, subHeader } = headers;

  useEffect(() => {
    const items = [
      // { label: 'test', to: '/test' },
      { label: 'all tasks', to: '/allTasks' },
      { label: 'add a task', to: '/addTask' },
      { label: 'create a category', to: '/createCategory' },
    ];

    setMenuItems(items);
  }, []);

  const BASE_CLASS = `block uppercase text-left px-7 py-3`;
  const ACTIVE_CLASS = ` border-l-8 border-blue-500 
      font-bold text-blue-500`;

  return (
    <div className='flex p-3 pt-1'>
      <div
        className='flex-none'
        onMouseLeave={e => {
          setDisplayMenu(false);
        }}
      >
        <button
          className=''
          onClick={e => {
            setDisplayMenu(true);
          }}
        >
          <FontAwesomeIcon icon={faBars} className={``} />
        </button>
        {displayMenu && (
          <div
            className={`border absolute left-0 bg-white h-5/6 w-3/4 z-40
            shadow-xl rounded-r-xl`}
          >
            <UserBadge />
            {menuItems.map((item, index) => {
              return (
                <NavLink
                  key={index}
                  to={item.to}
                  className={({ isActive }) => {
                    return BASE_CLASS + ' ' + (isActive ? ACTIVE_CLASS : ``);
                  }}
                  onClick={e => {
                    clearAppHeader();
                    setDisplayMenu(false);
                  }}
                >
                  {item.label}
                </NavLink>
              );
            })}
            <Logout
              className={BASE_CLASS}
              onClick={() => {
                setDisplayMenu(false);
              }}
            />
          </div>
        )}
      </div>
      <div className='grow'>
        <div className={`text-base ${HEADER_STYLES[style]}`}>{header}</div>
        <div className={`text-xs capitalize ${SUB_HEADER_STYLES[style]}`}>
          {subHeader}
        </div>
      </div>
    </div>
  );
};
Nav.propTypes = { headers: object, style: string };
