import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import store from './components/store/store';
import AddTask from './routes/addTask/AddTask';
import AllTasks from './routes/allTasks/AllTasks';
import CreateCategory from './routes/createCategory/CreateCategory';
import CategoryView from './components/views/CategoryView/CategoryView';
import { Login } from './components/Login';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          {/* when outside the nested path it only renders once */}
          {/* <Route path='/test' element={<Category/>}/> */}
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<App />}>
            <Route path='category/:categoryId' element={<CategoryView />} />
            <Route path='allTasks' element={<AllTasks />} />
            <Route path='addTask' element={<AddTask />} />
            <Route path='createCategory' element={<CreateCategory />} />
            <Route path='*' element={<h1>nothing here ... got lost?</h1>} />
          </Route>
        </Routes>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
