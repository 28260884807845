import { createSlice } from '@reduxjs/toolkit';

const initialState = {};
export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    addTask: (state, action) => {
      const { payload: taskList } = action;
      taskList.forEach(task => {
        state[task.category_id].tasks[task._id] = task;
      });
    },
    addTaskCategory: (state, action) => {
      const { payload: category } = action;
      state[category._id] = category;
    },
    markTaskAsDone: (state, action) => {
      const { category_id: categoryId, _id: taskId } = action.payload;
      state[categoryId].tasks[taskId] = action.payload;
    },
    reopenTask: (state, action) => {
      const { category_id: categoryId, _id: taskId } = action.payload;
      state[categoryId].tasks[taskId] = action.payload;
    },
    saveDefaultView: (state, action) => {
      const { _id: categoryId, default_view: defaultView } = action.payload;
      state[categoryId]['default_view'] = defaultView;
    },
    clearTasks: () => initialState,
  },
});

export const {
  addTask,
  addTaskCategory,
  markTaskAsDone,
  reopenTask,
  saveDefaultView,
  clearTasks,
} = tasksSlice.actions;

export default tasksSlice.reducer;
