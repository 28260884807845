import { string } from 'prop-types';
import { twMerge } from 'tailwind-merge';

const Divider = ({ styleOverrides }) => (
  <div className={twMerge(`border my-3 mx-12`, styleOverrides)} />
);

Divider.propTypes = {
  styleOverrides: string,
};

export default Divider;
