import { object, func } from 'prop-types';
import './styles.css';
import ToggleContainer from '../ToggleContainer/ToggleContainer';
import { fetchFilteredTasks } from './utils';

const FilteredTasks = ({
  categories,
  handleClick,
  handleOnTaskUpdate,
  tasksContainer,
}) => {
  const filteredCategories = fetchFilteredTasks({
    categories,
    containerComponent: ToggleContainer,
    containerStyleOverrides: {
      labeStylesOverrides: 'top-level-title font-bold pl-2',
      containerStylesOverrides: 'my-2',
    },
    tasksContainer,
    handleClick,
    handleOnTaskUpdate,
  });

  return (
    <div className='filtered-container text-left w-full z-10'>
      {filteredCategories}
    </div>
  );
};

FilteredTasks.propTypes = {
  categories: object,
  handleClick: func,
  tasksContainer: func,
  handleOnTaskUpdate: func,
};

export default FilteredTasks;
