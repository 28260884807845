import { useState } from 'react';
import { func, string, array, object } from 'prop-types';
import { hyphenate, proofFn } from '../utils/utils';
import { getInputClassName, getLabelClassName } from './utils';
import { errorMessageClassNames } from './styles';

const LabeledInput = ({
  label,
  inputClassName,
  labelClassName,
  onChange: onChangeFromProps,
  value,
  validationState = {},
  ...otherProps
}) => {
  const id = hyphenate(label);
  const [inputValue, setInputValue] = value ?? useState('');
  const onChange = e => {
    setInputValue(e.target.value);
    proofFn(onChangeFromProps)(e);
  };

  const { isValid, message, displayError } = validationState;
  const renderError = !isValid && displayError;

  return (
    <div>
      <label
        className={getLabelClassName(labelClassName, { renderError })}
        htmlFor={id}
      >
        {label}
      </label>
      <input
        id={id}
        value={inputValue}
        onChange={onChange}
        className={getInputClassName(inputClassName, { renderError })}
        {...otherProps}
      />
      <div className={errorMessageClassNames}>{renderError && message}</div>
    </div>
  );
};

LabeledInput.propTypes = {
  label: string,
  inputClassName: string,
  labelClassName: string,
  onChange: func,
  value: array,
  validationState: object,
};

export default LabeledInput;
