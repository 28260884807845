import FilteredTasks from '../../FilteredTasks/FilteredTasks';
import TaskCategory from '../../TaskCategory/TaskCategory';
import TodoTaskList from '../../TodoTaskList/TodoTaskList';
import {
  filterByLabelsHandler,
  filterByLocationHandler,
  clearAllFiltersHandler,
  getDefaultViewHandler,
} from './handlers';

const FULL_MODE = 'FULL';
const TODO_MODE = 'TODO';

const viewComponentsMap = {
  [FULL_MODE]: TaskCategory,
  [TODO_MODE]: TodoTaskList,
};

const menuOptionsLabelMap = {
  [FULL_MODE]: 'to-do mode',
  [TODO_MODE]: 'show details',
};

const newDefaultViewMap = {
  [FULL_MODE]: TODO_MODE,
  [TODO_MODE]: FULL_MODE,
};

export const getTasksContainerByView = ({ currentViewMode = FULL_MODE } = {}) =>
  viewComponentsMap[currentViewMode];

export const getViewComponent = ({
  currentViewMode = FULL_MODE,
  isFiltered,
} = {}) => {
  if (isFiltered) {
    return FilteredTasks;
  }
  return getTasksContainerByView({ currentViewMode });
};

export const getMenuOptions = ({
  categoryId,
  currentViewMode = FULL_MODE,
  isFiltered,
} = {}) => {
  const menuOptions = [
    [
      menuOptionsLabelMap[currentViewMode],
      getDefaultViewHandler(categoryId, newDefaultViewMap[currentViewMode]),
    ],
    ['Filter By Labels', filterByLabelsHandler],
    ['Filter By Location', filterByLocationHandler],
  ];

  if (isFiltered) {
    menuOptions.push(['Clear All Filters', clearAllFiltersHandler]);
  }

  return menuOptions;
};
