import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { storeUser } from '../store/userSlice';

const useLoginHandlers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googleOnLogin = userData => {
    dispatch(storeUser(userData));
    navigate('/');
  };

  return { googleOnLogin };
};

export default useLoginHandlers;
