import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const categoryCatalogSlice = createSlice({
  name: 'categoryCatalog',
  initialState,
  reducers: {
    setCategoryCatalog: (state, action) => action.payload,
    clearCategoryCatalog: () => initialState,
  },
});

export const { clearCategoryCatalog, setCategoryCatalog } =
  categoryCatalogSlice.actions;
export default categoryCatalogSlice.reducer;
