import { useState } from 'react';
import { useSelector } from 'react-redux';
import MultiLocationInput from './MultiLocationInput';

const useMultiLocationInput = props => {
  const state = useState('');
  const taskLocationList = useSelector(state => state.taskLocations.locations);
  const clearMultiLocationInput = () => state[1]('');
  const getMultiLocationInputValue = () => state[0];
  const getMultiLocationList = () => taskLocationList;
  return {
    MultiLocationInput: <MultiLocationInput {...props} value={state} />,
    state,
    clearMultiLocationInput,
    getMultiLocationInputValue,
    getMultiLocationList,
  };
};

export default useMultiLocationInput;
