import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loginMessage: '',
  pageLevelMessage: [],
  loadingMessage: { header: '', subHeader: '' },
};

export const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addLoginMessage: (state, action) => {
      state.loginMessage = action.payload.message;
    },
    removeLoginMessage: (state, action) => {
      state.loginMessage = '';
    },
    addPageLevelMessage: (state, action) => {
      state.pageLevelMessage.push({
        ...action.payload.message,
        id: new Date().getTime(),
      });
    },
    removePageLevelMessage: (state, action) => {
      state.pageLevelMessage = state.pageLevelMessage.filter(
        msg => msg.id !== action.payload.id,
      );
    },
    addLoadingMessage: (state, action) => {
      state.loadingMessage.header = action.payload.header;
      state.loadingMessage.subHeader = action.payload.subHeader;
    },
    removeLoadingMessage: (state, action) => {
      state.loadingMessage.header = '';
      state.loadingMessage.subHeader = '';
    },
    clearMessages: () => initialState,
  },
});

export const {
  addLoginMessage,
  removeLoginMessage,
  addPageLevelMessage,
  removePageLevelMessage,
  addLoadingMessage,
  removeLoadingMessage,
  clearMessages,
} = messageSlice.actions;
export default messageSlice.reducer;
