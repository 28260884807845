import { useEffect } from 'react';
import { func } from 'prop-types';
import { getCallBackHandler } from './handlers';

// TODO: replace this implementation with
// https://www.npmjs.com/package/@react-oauth/google
const GoogleLogin = ({ onLogin }) => {
  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID_GIS,
      callback: getCallBackHandler(onLogin),
    });

    google.accounts.id.renderButton(
      document.getElementById('google-login-container'),
      {
        theme: 'filled_blue',
        size: 'large',
        text: 'continue_with',
        shape: 'pill',
      },
    );
  }, []);

  return (
    <div className='flex justify-center'>
      <div id='google-login-container'></div>
    </div>
  );
};

GoogleLogin.propTypes = { onLogin: func };
export default GoogleLogin;
