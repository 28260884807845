import { array, func, string } from 'prop-types';
import { useEffect, useState } from 'react';
import TaskIcon from './TaskIcon';

const TaskIconSelector = props => {
  const { icons, onSelected, value, styleOverrides } = props;
  const [selectedIcon, setSelectedIcon] = useState(value);

  const getClassName = iconName => {
    const isSelected = iconName === selectedIcon;
    const className = ``;
    let additionalStyles = '';

    if (isSelected) {
      additionalStyles = 'text-green-500';
    }
    return className + ' ' + additionalStyles;
  };

  useEffect(() => {
    setSelectedIcon(value);
  }, [value]);

  return (
    <div className={`flex max-w-md justify-around mt-3 ${styleOverrides}`}>
      {icons.map((iconName, index) => {
        return (
          <div
            data-testid={`icon-container-${iconName}`}
            key={index}
            className={getClassName(iconName)}
            onClick={e => {
              onSelected({ iconName });
            }}
          >
            <TaskIcon icon={iconName} styleOverrides='cursor-pointer' />
          </div>
        );
      })}
    </div>
  );
};

TaskIconSelector.propTypes = {
  icons: array,
  onSelected: func,
  value: string,
  styleOverrides: string,
};

export default TaskIconSelector;
