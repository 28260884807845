import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from './const';

const initialState = { state: APP_STATE.ready, details: {} };

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setAppReady: (state, action) => {
      state.state = APP_STATE.ready;
      state.details = {};
    },
    setAppLoading: (state, action) => {
      state.state = APP_STATE.loading;
      state.details = {};
    },
    setAppError: (state, action) => {
      state.state = APP_STATE.error;
      state.details = { ...action.payload };
    },
    setAppExpiredSession: (state, action) => {
      state.state = APP_STATE.sessionExpired;
      state.details = { ...action.payload };
    },
    setAppLoggedOut: (state, action) => {
      state.state = APP_STATE.loggedOut;
      state.details = { ...action.payload };
    },
    clearAppState: () => initialState,
  },
});

export const {
  setAppReady,
  setAppLoading,
  setAppError,
  setAppExpiredSession,
  setAppLoggedOut,
  clearAppState,
} = appStateSlice.actions;
export default appStateSlice.reducer;
