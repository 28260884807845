// TODO move these utils to the root level
// I suspect this is what creates that weird Store error
// when adding `store` here. My guess is, it creates a
// circular reference and breakes the Jest initialization
// of the `singleCategoryViewSlice` module becuase
// `singleCategoryViewSlice.test.js` also imports `assign`
// and uses it in the tests. The alternative is to use `lodash`
export const assign = (target, path, value) => {
  let destination = target;
  for (let index = 0; index < path.length - 1; index++) {
    destination = destination[path[index]];
  }
  destination[path[path.length - 1]] = value;
};

export const getValueAtPath = (target, path) => {
  let destination = target;
  for (let index = 0; index < path.length; index++) {
    destination = destination[path[index]];
  }
  return destination;
};
