import { useState } from 'react';
import { Nav } from '../components/Nav';

const useNavComponent = () => {
  const [header, setHeader] = useState('');
  const [subHeader, setSubHeader] = useState('');
  const [style, setStyle] = useState('');
  const setNavHeader = ({ header, subHeader, style } = {}) => {
    setHeader(header ?? '');
    setSubHeader(subHeader ?? '');
    setStyle(style ?? '');
  };
  const clearNavHeader = () => setNavHeader();

  return {
    Nav: () => <Nav headers={{ header, subHeader }} style={style} />,
    clearNavHeader,
    setNavHeader,
  };
};

export default useNavComponent;
