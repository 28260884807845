import { createSlice } from '@reduxjs/toolkit';

const initialState = { header: '', subHeader: '' };

export const appHeader = createSlice({
  name: 'appHeader',
  initialState,
  reducers: {
    setHeader: (state, action) => {
      const {
        payload: { header, subHeader },
      } = action;
      state.header = header;
      state.subHeader = subHeader;
    },
    clearHeader: () => initialState,
  },
});

export const { setHeader, clearHeader } = appHeader.actions;
export default appHeader.reducer;
