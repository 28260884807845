import { useDispatch, useSelector } from 'react-redux';

import PageLevelMessage from '../PageLevelMessage/PageLevelMessage';
import PageMessage from '../PageLevelMessage/PageMessage';
import { removePageLevelMessage } from '../store/messagesSlice';

const PageLevelMessageContainer = props => {
  const dispatch = useDispatch();
  const messages = useSelector(state => state.messages.pageLevelMessage);

  const containerClassName = `fixed left-0 top-0 w-full z-50`;
  const cellClassName = ``;

  return (
    <div className={containerClassName}>
      {messages.map(({ text, type, duration, id }) => {
        return (
          <div key={id} className={cellClassName}>
            <PageLevelMessage
              messageObj={
                new PageMessage({
                  text,
                  type,
                  duration,
                })
              }
              onTimeOut={() => dispatch(removePageLevelMessage({ id }))}
            />
          </div>
        );
      })}
    </div>
  );
};

PageLevelMessageContainer.propTypes = {};

export default PageLevelMessageContainer;
