import store from '../../components/store/store';
import {
  addTaskCategory,
  // saveDefaultView,
} from '../../components/store/tasksSlice';

export const handleCategoryResponse = ({ categories }) => {
  for (const { 1: categoryData } of Object.entries(categories)) {
    store.dispatch(addTaskCategory(categoryData));
  }
};

// export const handleDefaultViewResponse = payload => {
//   if (!payload?.success) {
//     throw new Error('There was an eror updating your default view');
//   }
//   store.dispatch(saveDefaultView(payload));
// };
