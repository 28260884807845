import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { array } from 'prop-types';
import { useState } from 'react';
import { proofFn } from '../utils/utils';

const SideMenu = ({ menuOptions }) => {
  if (!menuOptions || !menuOptions.length) {
    return null;
  }
  const [displayMenu, setDisplayMenu] = useState(false);
  return (
    <div
      data-testid={'category-side-menu'}
      className={`absolute right-3 -top-2 text-right cursor-pointer select-none
      sm:right-6 sm:top-6 z-30 drop-shadow-xl w-10 h-10`}
      onClick={e => {
        setDisplayMenu(!displayMenu);
      }}
    >
      <FontAwesomeIcon icon={faEllipsis} />
      {displayMenu && (
        <div
          className='border bg-white p-5 grid grid-cols-1 gap-2 w-52
          absolute right-0'
          role='menu'
        >
          {menuOptions.map(([label, handler], index) => {
            return (
              <div
                key={index}
                role='menuitem'
                className='text-left hover:font-bold py-2'
                onClick={e => {
                  proofFn(handler)();
                }}
              >
                {label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

SideMenu.propTypes = { menuOptions: array };

export default SideMenu;
