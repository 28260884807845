import { createSlice } from '@reduxjs/toolkit';

const initialState = { locations: [] };
export const taskLocationsSlice = createSlice({
  name: 'taskLocations',
  initialState,
  reducers: {
    addLocation: (state, action) => {
      state.locations.push(action.payload);
    },
    setLocations: (state, action) => {
      state.locations = action.payload ?? [];
    },
    removeLocation: (state, action) => {
      state.locations.splice(action.payload, 1);
    },
    clearLocations: () => initialState,
  },
});

export const { addLocation, setLocations, removeLocation, clearLocations } =
  taskLocationsSlice.actions;
export default taskLocationsSlice.reducer;
