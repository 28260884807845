import { useDispatch, useSelector } from 'react-redux';
import { clearIcon as resetIcon, setIcon } from '../store/taskIconSlice';
import IconPicker from './IconPicker';

export const useIconPicker = props => {
  const dispatch = useDispatch();
  const value = useSelector(state => state.taskIcon.icon);
  const setValue = value => {
    dispatch(setIcon(value));
  };
  const state = [value, setValue];
  const clearIcon = () => {
    dispatch(resetIcon());
  };

  const getIcon = () => value;

  return {
    IconPicker: () => <IconPicker value={state} {...props} />,
    state,
    getIcon,
    clearIcon,
  };
};
