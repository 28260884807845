import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    storeUser: (state, action) => {
      state = Object.assign(state, { ...action.payload });
    },
    removeUser: (state, action) => {
      for (const key of Object.keys(state)) {
        delete state[key];
      }
    },
  },
});

export const { storeUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
