import { createNewTask } from '../../components/api/api';
import store from '../../components/store/store';
import { addTask } from '../../components/store/tasksSlice';

// TODO integrate these handlers in the AddTask component
export const createNewTaskHandler = async ({ taskData }) => {
  return createNewTask(taskData)
      .then(response => {
        const { status, message } = response;
        if (status === 'ERROR') {
          throw new Error(message);
        }
        return response;
      })
      .then(taskData => {
        store.dispatch(addTask(taskData));
      });
};

export const handleChange = (e, handler) => {
  const { value } = e.target;
  handler(value);
};
