import {
  markFilteredTaskAsDone,
  reopenFilteredTask,
} from '../store/singleCategoryViewSlice';
import store from '../store/store';

export const handleClick = ({ taskData, path }) => {
  const { status: { isComplete: hasBeenCompleted } = {} } = taskData;
  if (hasBeenCompleted) {
    store.dispatch(markFilteredTaskAsDone({ taskData, path }));
  } else {
    store.dispatch(reopenFilteredTask({ taskData, path }));
  }
};

export const getClickHandler =
  ({ clickHandlerFromProps, path }) =>
  async ({ taskData }) => {
    return clickHandlerFromProps({ taskData }).then(taskData =>
      handleClick({ taskData, path: [...path, taskData._id] }),
    );
  };
