import {
  setAppStateError,
  setAppStateExpiredSession,
  setAppStateLoading,
  setAppStateReady,
} from '../utils/utils';
import { LOADING_MSGS } from './constants';

const handleResponse = async response => {
  setAppStateReady();
  if (!response.ok) {
    const { status, statusText } = response;
    const errorMsg = `[${status}] ${statusText}`;
    const cause = { status, statusText };
    throw new Error(errorMsg, { cause });
  }
  return response.json();
};

const handleError = async error => {
  const isExpiredSession =
    error.cause?.status === 401 || error.message.includes('[401]');

  if (isExpiredSession) {
    setAppStateExpiredSession(error);
  } else {
    setAppStateError(error);
  }
  throw error;
};

const getLoadingMessages = url => {
  const key = url.replace(/\//g, '').split('?')[0];
  return LOADING_MSGS[key] ?? {};
};

export const getPostOptions = (body = {}) => ({
  method: 'POST',
  body: JSON.stringify(body),
  headers: { 'Content-Type': 'application/json' },
});

export const callAPI = async (url, options = {}) => {
  setAppStateLoading(getLoadingMessages(url));
  return fetch('/api' + url, { ...options, credentials: 'include' })
    .then(handleResponse)
    .catch(handleError);
};
