import { faSquareFull } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { func, object, string } from 'prop-types';

const getClassNames = ({ isComplete }) => {
  const baseClassName = `todo-task-list-item relative flex space-x-4
    cursor-pointer py-3 sm:py-1`;
  return baseClassName + (isComplete ? ' text-zinc-400 italic' : '');
};

const TodoTask = props => {
  const { handleClick, status: { isComplete } = {}, title } = props;
  return (
    <div
      className={getClassNames({ isComplete }) + ` sm:hover:text-zinc-400`}
      onClick={handleClick}
    >
      <div
        className={`relative justify-self-center
    place-self-center flex justify-center items-center
     grow-0 text-2xl sm:text-lg `}
      >
        <FontAwesomeIcon
          className='hover:bg-sky-100'
          icon={isComplete ? faCheck : faSquareFull}
        />
      </div>
      <div
        className={`flex items-center text-left text-lg
      `}
      >
        {title}
      </div>
    </div>
  );
};

TodoTask.propTypes = { handleClick: func, status: object, title: string };

export default TodoTask;
