import { createRef } from 'react';
import { object, func, string, number } from 'prop-types';

import './PageLevelMessage.css';
import { getContainerClassNames } from './utils';
import { ProgressBar } from './styledComponents';

const PageLevelMessage = ({
  messageObj: { text, duration, type },
  onTimeOut,
}) => {
  const progressBarRef = createRef();
  const setAnimationState = state =>
    (progressBarRef.current.style.animationPlayState = state);

  return (
    <div
      className={`outer-message-container flex justify-center 
      w-full pt-2 pb-3 z-50`}
      onMouseOver={() => setAnimationState('running')}
    >
      <div
        className={getContainerClassNames(type)}
        onMouseOver={e => {
          e.stopPropagation();
          e.preventDefault();
          setAnimationState('paused');
        }}
        onAnimationEnd={onTimeOut}
      >
        <div className='message-text m-2'>{text}</div>
        <ProgressBar
          data-testid='pb-test-id'
          className='progress-bar'
          type={type}
          duration={duration}
          ref={progressBarRef}
        />
      </div>
    </div>
  );
};

PageLevelMessage.propTypes = {
  messageObj: object,
  onTimeOut: func,
  text: string,
  type: string,
  duration: number,
};

export default PageLevelMessage;
