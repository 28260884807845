import { bool, object, string, func } from 'prop-types';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import AddTaskInLine from '../AddTaskInLine/AddTaskInLine';
import Inline from '../CTAs/Inline/Inline';
import { initEditTaskModal } from '../../routes/allTasks/utils';
import EditTask from '../EditTask/EditTask';
import Modal from '../Modal/Modal';
import TaskList from '../TaskList/TaskList';
import ToggleButton from '../ToggleButton/ToggleButton';
import { proofAsyncFn, proofFn } from '../utils/utils';

const TaskCategory = props => {
  const {
    _id,
    className,
    description,
    displayOnly,
    displayInlineOptions,
    displayToggle,
    displayToggleExpanded,
    title,
    tasks,
    handleClick,
    handleOnDelete,
    onToggle: onToggleFromProps,
    handleOnTaskUpdate: handleOnTaskUpdateFromProps,
  } = props;
  const [displayAddTask, setDisplayAddtask] = useState(false);
  const [displayTaskList, setDisplayTaskList] = useState(
    displayToggleExpanded ?? true,
  );
  const displayHeader = !!title;
  const onToggle = proofFn(onToggleFromProps);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editTaskId, setEditTaskId] = useState('');

  const handleOnEdit = async ({ taskData }) => {
    initEditTaskModal({ taskData });
    setEditTaskId(taskData._id);
    setShowEditModal(true);
  };

  const handleOnTaskUpdate = async updatedTaskData => {
    setShowEditModal(false);
    await proofAsyncFn(handleOnTaskUpdateFromProps)(updatedTaskData);
  };
  /* TODO
  - the tasks rows are not even, make sure
  the space above and below is the same
   */

  return (
    <div className={className}>
      {displayOnly && !title ? (
        <div
          role='progressbar'
          className='text-sm my-2 w-60 bg-zinc-100 border border-zinc-200
            h-5 animate-pulse'
        />
      ) : (
        <>
          {displayHeader && (
            <div className='relative flex flex-wrap my-3 sm:flex-nowrap'>
              {displayToggle && (
                <ToggleButton
                  styleOverrides='w-6 text-left sm:w-6'
                  expanded={displayToggleExpanded}
                  onClick={isExpanded => {
                    onToggle(isExpanded);
                    setDisplayTaskList(isExpanded);
                  }}
                />
              )}
              <div className='basis-11/12 sm:w-7/12 grow'>
                <NavLink to={`/category/${_id}`}>
                  <div className='flex flex-wrap sm:divide-x-2 sm:flex-nowrap'>
                    <div
                      className='text-base text-left uppercase font-bold
                    w-fit sm:w-52 sm:shrink-0'
                    >
                      {title}
                    </div>
                    <div
                      className='text-xs text-left capitalize sm:pl-2
                    sm:ml-2 my-auto w-full overflow-auto'
                    >
                      {description}
                    </div>
                  </div>
                </NavLink>
              </div>
              {!displayOnly && displayInlineOptions && displayTaskList && (
                <div
                  className='flex justify-end items-end mt-1 w-full
               sm:w-3/12'
                >
                  <Inline
                    text='add task'
                    styleOverrides='w-24 sm:w-full'
                    showToggleArrow={true}
                    handler={e => {
                      setDisplayAddtask(!displayAddTask);
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {displayAddTask && (
            <div className='rounded-lg'>
              <AddTaskInLine categoryData={props} />
            </div>
          )}
        </>
      )}
      {displayTaskList && (
        <div
          className='task-category p-1 sm:p-5 border rounded
        border-gray-400 grid'
        >
          <TaskList
            displayOnly={displayOnly}
            categoryId={_id}
            tasks={tasks}
            handleClick={handleClick}
            handleOnDelete={handleOnDelete}
            handleOnEdit={handleOnEdit}
          />
        </div>
      )}

      {showEditModal && (
        <Modal
          title={title ?? 'Edit Task'}
          onClose={() => {
            setShowEditModal(false);
          }}
          content={<EditTask id={editTaskId} onUpdate={handleOnTaskUpdate} />}
        />
      )}
    </div>
  );
};

TaskCategory.propTypes = {
  _id: string,
  className: string,
  description: string,
  displayOnly: bool,
  displayInlineOptions: bool,
  displayToggle: bool,
  displayToggleExpanded: bool,
  tasks: object,
  title: string,
  handleClick: func,
  handleOnDelete: func,
  onToggle: func,
  handleOnTaskUpdate: func,
};

export default TaskCategory;
