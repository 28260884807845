import { useState } from 'react';

const useValidationState = ({ validator } = {}) => {
  const initialState = { isValid: true };
  const [validationDetails, setValidationDetails] = useState(initialState);

  const resetValidator = () => setValidationDetails(initialState);
  const validate = () => {
    resetValidator();
    const isValid = validator();
    setValidationDetails({ isValid });
    return isValid;
  };

  return {
    validationDetails,
    validate,
    resetValidator,
  };
};

export default useValidationState;
