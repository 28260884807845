import {
  setAppError,
  setAppExpiredSession,
  setAppLoading,
  setAppLoggedOut,
  setAppReady,
} from '../store/appStateSlice';
import { addPageLevelError } from '../store/errorMessagesSlice';
import {
  addLoadingMessage,
  addPageLevelMessage,
  removeLoadingMessage,
} from '../store/messagesSlice';
import store from '../store/store';
import { clearHeader, setHeader } from '../store/appHeaderSlice';

export const isEmpty = obj =>
  !!(obj && Object.keys(obj).length === 0 && obj.constructor === Object);
export const isAFunction = fn => typeof fn === 'function';
export const isAsyncFunction = fn =>
  isAFunction(fn) && fn.constructor.name === 'AsyncFunction';

export const proofFn = fn => {
  if (isAFunction(fn)) {
    return fn;
  }
  return new Function();
};

export const proofAsyncFn = fn => {
  if (isAsyncFunction(fn)) {
    return fn;
  }
  return async props => proofFn(fn)(props);
};

export const serializeError = error => {
  let payload = error ?? {};

  if (error instanceof Error) {
    payload = {
      message: error.message,
      name: error.name,
      cause: error.cause ?? '',
    };
  }

  return payload;
};

export const hyphenate = value =>
  value.trim().toLowerCase().replace(/\s/g, '-');

export const displayPageError = errorDetails => {
  if (!errorDetails) {
    return;
  }

  store.dispatch(addPageLevelError(serializeError(errorDetails)));
};

export const setAppStateReady = () => {
  store.dispatch(removeLoadingMessage());
  store.dispatch(setAppReady());
};

export const setAppStateLoading = ({ header, subHeader } = {}) => {
  store.dispatch(addLoadingMessage({ header, subHeader }));
  store.dispatch(setAppLoading());
};

export const setAppStateError = error => {
  store.dispatch(setAppError(serializeError(error)));
};

export const setAppStateExpiredSession = error => {
  store.dispatch(setAppExpiredSession(serializeError(error)));
};

export const setAppStateLggedOut = data => {
  store.dispatch(setAppLoggedOut(data));
};

export const clearAppHeader = () => store.dispatch(clearHeader());

export const setAppHeader = (headers = {}) =>
  store.dispatch(setHeader(headers));

export const displayPageLevelMessage = message => {
  store.dispatch(addPageLevelMessage({ message }));
};
