import { bool, string, func, shape } from 'prop-types';
import { useState } from 'react';
import TaskCategory from '../../components/TaskCategory/TaskCategory';
import { proofFn } from '../../components/utils/utils';
import { updateCategoryTasks } from './utils';
// eslint-disable-next-line max-len
import { markTaskForDeletion } from '../../components/views/CategoryView/handlers';

const CategoryContainer = props => {
  const {
    displayToggle,
    displayToggleExpanded,
    categoryId,
    onToggle: onToggleFromProps,
    styleOverrides: {
      mainContainerClassName = '',
      cardStyles = '',
      categoryContainerClassName = '',
    } = {},
    handleClick,
    ...categoryProps
  } = props;

  const [isExpanded, setIsExpanded] = useState(
    !!(displayToggle && displayToggleExpanded),
  );

  const onToggle = isExpanded => {
    setIsExpanded(isExpanded);
    proofFn(onToggleFromProps)(isExpanded);
  };

  const handleOnTaskUpdate = async updatedTaskData => {
    await updateCategoryTasks({ categoryId });
  };

  const handleOnDelete = async ({ taskData }) =>
    markTaskForDeletion(taskData).then(() => {
      updateCategoryTasks({ categoryId });
    });

  return (
    <div
      className={mainContainerClassName + (isExpanded ? '' : cardStyles)}
      key={categoryId}
    >
      <TaskCategory
        className={categoryContainerClassName}
        key={categoryId}
        displayInlineOptions={true}
        displayToggle={true}
        displayToggleExpanded={true}
        handleClick={handleClick}
        handleOnTaskUpdate={handleOnTaskUpdate}
        handleOnDelete={handleOnDelete}
        onToggle={onToggle}
        {...categoryProps}
      />
    </div>
  );
};

CategoryContainer.propTypes = {
  displayToggle: bool,
  displayToggleExpanded: bool,
  categoryId: string,
  onToggle: func,
  styleOverrides: shape({
    mainContainerClassName: string,
    cardStyles: string,
    categoryContainerClassName: string,
  }),
  handleClick: func,
};

export default CategoryContainer;
