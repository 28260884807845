import { getCategoryCatalog, updateTask } from '../api/api';
import { setCategoryCatalog } from '../store/categoryCatalogSlice';
import store from '../store/store';
import { displayPageError } from '../utils/utils';

export const initCategoryCalog = () =>
  getCategoryCatalog()
    .then(categoryCatalog => {
      store.dispatch(setCategoryCatalog(categoryCatalog));
    })
    .catch(error => {
      displayPageError(error);
    });

export const saveChanges = taskData =>
  updateTask(taskData).catch(error => {
    displayPageError(error);
  });
