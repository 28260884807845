import styled from 'styled-components';
import { getBGColor } from './utils';

export const ProgressBar = styled.div`
  position: relative;
  bottom: -5px;
  left: -1px;
  width: 100%;
  height: 4px;
  animation: progress-animation ${props => props.duration / 1000}s forwards;
  background-color: ${props => getBGColor(props.type)};
`;
