import { useSelector } from 'react-redux';
import Loading from '../Loading/Loading';
import { APP_STATE } from '../store/const';

const PageLevelLoading = () => {
  const { header, subHeader } = useSelector(
    state => state.messages.loadingMessage,
  );
  const appState = useSelector(state => state.appState);
  const showLoading = appState.state === APP_STATE.loading;

  if (!showLoading) {
    return null;
  }

  return (
    <div>
      <Loading header={header} subHeader={subHeader} showSpinner={true} />
    </div>
  );
};

PageLevelLoading.propTypes = {};

export default PageLevelLoading;
