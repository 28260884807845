import { createSlice } from '@reduxjs/toolkit';

const initialState = { icon: '' };

export const taskIconSlice = createSlice({
  name: 'taskIcon',
  initialState,
  reducers: {
    setIcon: (state, action) => {
      state.icon = action.payload;
    },
    clearIcon: () => initialState,
  },
});

export const { clearIcon, setIcon } = taskIconSlice.actions;
export default taskIconSlice.reducer;
