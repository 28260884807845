import { TYPES } from './PageMessage';

export const getContainerClassNames = type => {
  let className = `border w-2/3 text-zinc-600 font-medium`;
  if (type === TYPES.ERROR) {
    className += ' bg-red-100 border-red-200';
  } else if (type === TYPES.WARNING) {
    className += ' bg-yellow-100 border-yellow-200';
  } else if (type === TYPES.SUCCESS) {
    className += ' bg-green-100 border-green-200';
  } else {
    className += ' bg-gray-100 border-gray-200';
  }
  return className;
};

export const getBGColor = type => {
  let color = '';
  if (type === TYPES.ERROR) {
    color = 'rgb(248 113 113)';
  } else if (type === TYPES.WARNING) {
    color = 'rgb(250 204 21)';
  } else if (type === TYPES.SUCCESS) {
    color = 'rgb(74 222 128)';
  } else {
    color = 'rgb(156 163 175)';
  }
  return color;
};
