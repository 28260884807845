import { string, element, func } from 'prop-types';
import './styles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { proofFn } from '../utils/utils';

const Modal = ({ title, content, onClose: onCloseFromProps }) => {
  const backgroundResponsive = `sm:flex sm:items-center sm:justify-center`;
  const containerResponsive = `sm:w-full sm:max-w-2xl sm:mx-16
  sm:relative sm:rounded-xl`;

  const backgroundStyles = `fixed top-0 left-0 w-full h-full
  backdrop-blur-sm bg-zinc-400/75 z-20 ${backgroundResponsive}`;
  const containerStyles = `main-container fixed bottom-0 bg-white
  w-full mt-5 rounded-t-3xl grid-rows-2 ${containerResponsive}`;

  const onClose = () => {
    proofFn(onCloseFromProps)();
  };

  return (
    <div className={backgroundStyles}>
      <div className={containerStyles}>
        <div className='flex m-1 p-1'>
          <div className='text-xl inline-block w-full ml-6 select-none'>
            {title}
          </div>
          <div>
            <button
              data-testid='modal-close-btn'
              className='mx-auto w-6'
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        </div>
        <div
          className='content w-auto overflow-auto
        flex justify-center mx-2 sm:m-5'
        >
          {content}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: string,
  content: element,
  onClose: func,
};

export default Modal;
