import { displayPageLevelMessage, proofAsyncFn } from '../utils/utils';
import { saveChanges } from './api';
import { DEFAULT_DURATION, TYPES } from '../PageLevelMessage/PageMessage';

export const handleOnSubmit = async ({ taskData, onUpdate }) =>
  saveChanges(taskData)
    .then(async updatedTaskData => {
      await proofAsyncFn(onUpdate)(updatedTaskData);
    })
    .then(() => {
      displayPageLevelMessage({
        text: 'Task Updated Successfully',
        duration: DEFAULT_DURATION,
        type: TYPES.SUCCESS,
      });
    });
