import { createSlice } from '@reduxjs/toolkit';

const initialState = { name: '' };

export const taskNameSlice = createSlice({
  name: 'taskName',
  initialState,
  reducers: {
    setTaskName: (state, action) => {
      state.name = action.payload;
    },
    clearTaskName: () => initialState,
  },
});

export const { setTaskName, clearTaskName } = taskNameSlice.actions;
export default taskNameSlice.reducer;
