import { handleCredentialResponse } from '../api/api';
import { proofFn } from '../utils/utils';

export const getCallBackHandler = onLogin => {
  return async googleData => {
    const userData = await handleCredentialResponse(googleData);
    if (userData.status !== 'OK') {
      throw new Error('Login was unsuccessful, please try again in a moment', {
        cause: userData,
      });
    }
    proofFn(onLogin)(userData);
  };
};
