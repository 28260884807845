export const LOADING_MSGS = {
  continue: {
    header: 'Signing you in',
    subHeader: 'please, hold on tight',
  },
  tasksByCategory: {
    header: 'Loading All Your Tasks',
    subHeader: '',
  },
  categoryCatalog: {
    header: 'Loading Your Categories',
    subHeader: '',
  },
  addTask: {
    header: 'Adding New Task',
    subHeader: '',
  },
  newCategory: {
    header: 'Creating A New Category',
    subHeader: '',
  },
  reopenTask: {
    header: 'Reopening Task',
    subHeader: '',
  },
  markTaskAsDone: {
    header: 'Checking Task Off',
    subHeader: '( Awesome! )',
  },
  logout: {
    header: 'Logging You Out',
    subHeader: '( See You Soon! )',
  },
  updateCategoryDefaultView: {
    header: 'Switching View',
    subHeader: '',
  },
  categoryData: {
    header: 'Fetching Latest Category Data',
    subHeader: '',
  },
  userLogin: {
    header: 'Verifying Credentials',
    subHeader: '',
  },
};
