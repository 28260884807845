import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { array, func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { addLocation, removeLocation } from '../store/taskLocationsSlice';
import { proofFn } from '../utils/utils';

const MultiLocationInput = ({
  inputClassName,
  labelClassName,
  listClassName,
  onChange,
  onAdd,
  value,
}) => {
  const dispatch = useDispatch();
  const [taskLocation, setTaskLocation] = value;
  const locationsList = useSelector(state => state.taskLocations.locations);
  const propsOnChange = proofFn(onChange);
  const propsOnAdd = proofFn(onAdd);
  const onChangehandler = e => {
    propsOnChange(e);
    setTaskLocation(e.target.value);
  };

  return (
    <>
      <label className={labelClassName} htmlFor='task-location-no-autofill'>
        Location
      </label>
      <input
        id='task-location-no-autofill'
        value={taskLocation}
        onChange={onChangehandler}
        className={inputClassName}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (!taskLocation) {
              return;
            }
            dispatch(addLocation(taskLocation.trim()));
            propsOnAdd(taskLocation.trim());
            setTaskLocation('');
          }
        }}
      ></input>
      <div
        role='list'
        className={`flex flex-wrap mt-2 text-sm text-zinc-500
        ${listClassName}`}
      >
        {locationsList.map((taskLocation, index) => (
          <div
            role='listitem'
            key={index}
            className='p-0 flex m-1 rounded-none pr-1 cursor-pointer'
            onClick={e => dispatch(removeLocation(index))}
          >
            <div className='m-0 p-0 inline pt-1'>
              <FontAwesomeIcon className='px-1' icon={faLocationDot} />
            </div>
            <div
              className='m-0 py-1 inline text-sm
                  sm:hover:line-through sm:hover:italic'
            >
              {taskLocation}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

MultiLocationInput.propTypes = {
  inputClassName: string,
  labelClassName: string,
  listClassName: string,
  onChange: func,
  onAdd: func,
  value: array,
};

export default MultiLocationInput;
