import { func, string, object } from 'prop-types';
import Divider from '../Divider/Divider';
import { getFilteredTasks } from '../TaskList/utils';
import TodoTask from '../TodoTask/TodoTask';

const TodoTaskList = ({ className, categoryId, tasks, title, handleClick }) => {
  const { completedTasks, openTasks } = getFilteredTasks({
    component: TodoTask,
    categoryId,
    handleClick,
    tasks,
  });
  const showDivider = completedTasks.length > 0 && openTasks.length > 0;
  const displayHeader = Boolean(title);

  return (
    <div className={className}>
      {displayHeader && (
        <div
          className={`text-base text-center uppercase font-bold mb-5
          w-full tracking-widest`}
        >
          {title}
        </div>
      )}
      <div
        data-testid='todo-tasks-container'
        className={`todo-task-category border sm:gap-2
        ${displayHeader ? 'p-5' : 'py-0 px-5'} rounded border-gray-400 grid`}
      >
        {openTasks}
        {showDivider && <Divider styleOverrides='my-2 mx-5' />}
        {completedTasks}
      </div>
    </div>
  );
};

TodoTaskList.propTypes = {
  className: string,
  categoryId: string,
  handleClick: func,
  tasks: object,
  title: string,
};

export default TodoTaskList;
