import { useDispatch } from 'react-redux';
// eslint-disable-next-line max-len
import { clearSingleCategoryViewData } from '../components/store/singleCategoryViewSlice';
import { clearLabels } from '../components/store/addLabelsSlice';
import { clearHeader } from '../components/store/appHeaderSlice';
import { clearErrorMessages } from '../components/store/errorMessagesSlice';
import { clearMessages } from '../components/store/messagesSlice';
import { clearTasks } from '../components/store/tasksSlice';
import { clearLocations } from '../components/store/taskLocationsSlice';
import { clearTaskName } from '../components/store/taskNameSlice';
import { clearTaskNames } from '../components/store/taskNamesSlice';
import { clearCategories } from '../components/store/taskCategoriesSlice';
import { clearIcon } from '../components/store/taskIconSlice';
import { clearCategoryCatalog } from '../components/store/categoryCatalogSlice';

const useResetStore = () => {
  const dispatch = useDispatch();
  const resetAppState = () => {
    dispatch(clearLabels());
    dispatch(clearErrorMessages());
    dispatch(clearTasks());
    dispatch(clearMessages());
    dispatch(clearTaskNames());
    dispatch(clearTaskName());
    dispatch(clearLocations());
    dispatch(clearSingleCategoryViewData());
    dispatch(clearHeader());
    dispatch(clearCategories());
    dispatch(clearIcon());
    dispatch(clearCategoryCatalog());
  };
  return resetAppState;
};

export default useResetStore;
