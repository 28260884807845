import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { createNewCategory } from '../../components/api/api';
import { displayPageError } from '../../components/utils/utils';
import useNavContext from '../../hooks/useNavContext';

const CreateCategory = () => {
  // TODO these constants have to be abstracted and reused
  // here and in the AddTask component
  // TODO actually the whole input  field should be abstracted
  const mainContainerClassName = `mx-4
  sm:w-3/4 sm:mt-8 sm:mx-auto sm:shadow-xl sm:rounded-lg sm:bg-white sm:p-5`;
  const borderClassName = `border border-zinc-400`;
  const fieldContainerClassName = `pb-2 mb-3`;
  const labelClassName = `inline-block w-full text-sm
      text-left text-zinc-400 uppercase`;
  const inputClassName = `${borderClassName} mt-3 pb-3 pl-2 w-full border-0
    border-b-2 focus:border-lime-600 focus:outline-none bg-inherit`;
  const submitClassName = `border-2 rounded-md border-emerald-400 w-full py-3
    bg-emerald-400 text-white font-bold uppercase
    shadow-md shadow-emerald-400/20`;

  const [categoryTitle, setCategoryTitle] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const navigate = useNavigate();
  const { setNavHeader } = useContext(useNavContext());

  useEffect(() => {
    setNavHeader({ header: 'New Category or Project' });
  }, []);

  const onChangehandler = e => {
    const { id, value } = e.target;
    switch (id) {
      case 'category-name':
        setCategoryTitle(value);
        break;
      case 'category-description':
        setCategoryDescription(value);
        break;
    }
  };

  const resetInputFields = () => {
    setCategoryTitle('');
    setCategoryDescription('');
  };

  return (
    <div className={mainContainerClassName}>
      <form
        onSubmit={e => {
          // TODO abstract this handler
          // TODO abstract logic and components to be reused
          // here and in the AddTask component
          e.preventDefault();
          const payload = {
            title: categoryTitle,
            description: categoryDescription,
          };
          createNewCategory(payload)
            .then(response => {
              const { status, message } = response;
              if (status === 'ERROR') {
                displayPageError(response);
              }
              resetInputFields();
              const SuccessMessage = () => (
                <div>
                  <div>{message}</div>
                  <button
                    className='mx-auto'
                    onClick={e => {
                      navigate('/addTask');
                    }}
                  >
                    <span
                      className={`uppercase text-xs text-blue-500 
                    font-bold`}
                    >
                      add tasks now
                    </span>
                  </button>
                </div>
              );
              toast.success(<SuccessMessage />);
            })
            .catch(error => {
              displayPageError(error);
            });
        }}
      >
        <div className={fieldContainerClassName}>
          <label className={labelClassName} htmlFor='category-name'>
            Category or Project
          </label>
          <input
            id='category-name'
            value={categoryTitle}
            onChange={onChangehandler}
            className={inputClassName}
          ></input>
        </div>
        <div className={fieldContainerClassName}>
          <label className={labelClassName} htmlFor='category-description'>
            Description
          </label>
          <input
            id='category-description'
            value={categoryDescription}
            onChange={onChangehandler}
            className={inputClassName}
          ></input>
        </div>
        <div className='py-5'>
          <input
            className={submitClassName}
            type='submit'
            value='create'
          ></input>
        </div>
      </form>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default CreateCategory;
