import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { func, string } from 'prop-types';

const ErrorMessage = ({ message, onClick }) => {
  if (!message) {
    return null;
  }
  return (
    <div
      className={`border border-red-600 text-red-600
            capitalize rounded bg-red-100 mx-3 py-1 relative`}
      role="alert"
    >
      {message}
      <div
        role="button"
        onClick={onClick}
        className='absolute top-0 right-0 w-8 h-8 '
      >
        <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
      </div>
    </div>
  );
};

ErrorMessage.propTypes = { message: string, onClick: func };
export default ErrorMessage;
