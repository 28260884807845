import { DETAIL_STYLE, TODO_STYLE } from './constants';

export const HEADER_STYLES = {
  [TODO_STYLE]: `text-base text-center uppercase font-bold tracking-widest`,
  [DETAIL_STYLE]: `text-base text-center uppercase font-bold`,
};
export const SUB_HEADER_STYLES = {
  [TODO_STYLE]: ``,
  [DETAIL_STYLE]: `text-xs text-center capitalize w-full`,
};
