import { configureStore } from '@reduxjs/toolkit';
import errorMessagesReducer from './errorMessagesSlice';
import messageReducer from './messagesSlice';
import tasksReducer from './tasksSlice';
import userReducer from './userSlice';
import addLabelsReducer from './addLabelsSlice';
import appStateReducer from './appStateSlice';
import taskNamesReducer from './taskNamesSlice';
import taskNameReducer from './taskNameSlice';
import taskLocationsReducer from './taskLocationsSlice';
import singleCategoryViewReducer from './singleCategoryViewSlice';
import AppHeaderReducer from './appHeaderSlice';
import taskCategoriesReducer from './taskCategoriesSlice';
import taskIconReducer from './taskIconSlice';
import categoryCatalogReducer from './categoryCatalogSlice';

const store = configureStore({
  reducer: {
    addLabel: addLabelsReducer,
    categoryCatalog: categoryCatalogReducer,
    errorMessages: errorMessagesReducer,
    tasks: tasksReducer,
    user: userReducer,
    messages: messageReducer,
    appState: appStateReducer,
    taskNames: taskNamesReducer,
    taskName: taskNameReducer,
    taskLocations: taskLocationsReducer,
    taskCategories: taskCategoriesReducer,
    taskIcon: taskIconReducer,
    singleCategoryView: singleCategoryViewReducer,
    appHeader: AppHeaderReducer,
  },
});

export default store;
