export const mainContainerClassName = `w-full px-3 sm:px-6 pb-3 sm:pb-3`;
export const borderClassName = `border border-zinc-400`;
export const fieldContainerClassName = `pb-2 mb-3`;
export const inputClassName = `${borderClassName} mt-3 pb-3 pl-2 w-full border-0
  border-b-2 focus:border-lime-600 focus:outline-none bg-inherit`;
export const labelClassName = `inline-block w-full text-sm
  text-left text-zinc-400 uppercase`;
export const submitClassName = `border-2 rounded-md border-emerald-400 w-full
    py-3 text-white bg-emerald-400 font-bold uppercase shadow-md
    shadow-emerald-400/20`;
