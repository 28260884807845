export default class Filter {
  static FILTER_BY_LABELS = 'byLabels';
  static FILTER_BY_LOCATION = 'byLocation';

  #DEFAULT_LOCATION_NAME = '[No Location]';
  #DEFAULT_LABEL_NAME = '[No Labeled]';

  constructor() {}

  #byLocation(tasks) {
    const result = this.#filterBy({
      tasks,
      filterCriteria: 'location',
      defaultFilterValue: this.#DEFAULT_LOCATION_NAME,
    });

    return result;
  }

  #byLabels(tasks) {
    const result = this.#filterBy({
      tasks,
      filterCriteria: 'labels',
      defaultFilterValue: this.#DEFAULT_LABEL_NAME,
    });

    return result;
  }

  #findTasksAndFilter(category, categoryId, filteringFunction) {
    const self = this;
    let result = {};
    const { title, tasks, ...currentCategory } = category[categoryId];

    if (tasks) {
      result = filteringFunction(tasks);
    } else {
      const subCatsIds = Object.keys(currentCategory);
      for (const subCatid of subCatsIds) {
        result[subCatid] = self.#findTasksAndFilter(
          currentCategory,
          subCatid,
          filteringFunction,
        );
      }
    }

    if (title) {
      result.title = title;
    }
    return result;
  }

  #filterBy({ tasks, filterCriteria, defaultFilterValue }) {
    const result = {};
    const taskIdList = Object.keys(tasks);

    for (const taskId of taskIdList) {
      const currentTask = tasks[taskId];

      const listToFilterBy = Boolean(currentTask[filterCriteria]?.length) ?
        currentTask[filterCriteria] :
        [defaultFilterValue];

      for (const filterValue of listToFilterBy) {
        const filterKey = filterValue || defaultFilterValue;
        result[filterKey] = result[filterKey] || { tasks: {} };
        result[filterKey].tasks[taskId] = currentTask;
        result[filterKey].title = filterKey;
      }
    }

    return result;
  }

  #traverseCategoriesAndFilterTasks({ categories, filteringFunction }) {
    const self = this;
    const result = {};
    const categoryIdList = Object.keys(categories);

    for (const categoryId of categoryIdList) {
      const filteredTasks = self.#findTasksAndFilter(
        categories,
        categoryId,
        filteringFunction,
      );

      result[categoryId] = filteredTasks;
    }
    return result;
  }

  #printTasks(tasks, tab = '') {
    const taskIdList = Object.keys(tasks);
    for (const taskId of taskIdList) {
      const currentTask = tasks[taskId];
      const { title } = currentTask;
      console.log(`${tab}${tab}- ${title}`);
    }
    console.log('\n');
  }

  #findTasksAndPrint(categories, categoryId, tab = '') {
    const self = this;
    const currentCategory = categories[categoryId];
    const { title, tasks, ...filteredSubCategories } = currentCategory;

    console.log(`${tab}*** ${title} ***`);

    if (tasks) {
      self.#printTasks(tasks, tab);
    } else {
      const subCatIdList = Object.keys(filteredSubCategories);
      for (const subCatId of subCatIdList) {
        self.#findTasksAndPrint(filteredSubCategories, subCatId, tab + '\t');
      }
    }
  }

  findTasksAndPrint(categories) {
    const self = this;
    const categoryIdList = Object.keys(categories);
    for (const categoryId of categoryIdList) {
      self.#findTasksAndPrint(categories, categoryId);
    }
  }

  byLocation(categories) {
    const self = this;
    const result = self.#traverseCategoriesAndFilterTasks({
      categories,
      filteringFunction: self.#byLocation.bind(self),
    });

    return result;
  }

  byLabels(categories) {
    const self = this;
    const result = self.#traverseCategoriesAndFilterTasks({
      categories,
      filteringFunction: self.#byLabels.bind(self),
    });

    return result;
  }
}
