import { bool, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconMap } from './constants/iconMap';

const TaskIcon = props => {
  const { icon, isComplete, styleOverrides } = props;

  const getClassName = ({ icon, isComplete }) => {
    const customClassName = icon ?
      `bg-sky-100 ` :
      `bg-zinc-100 border-zinc-200 border animate-pulse`;
    const baseClassName = `relative row-span-4 ${
      isComplete ? 'justify-self-start' : 'justify-self-center'
    }
    place-self-center flex justify-center items-center rounded-full
    w-10 h-10 grow-0 sm:w-12 sm:h-12 ${customClassName} ${styleOverrides}`;
    return baseClassName;
  };

  return (
    <div className={getClassName({ icon, isComplete })}>
      {icon && <FontAwesomeIcon icon={iconMap[icon]} />}
    </div>
  );
};

TaskIcon.propTypes = {
  icon: string,
  isComplete: bool,
  styleOverrides: string,
};

export default TaskIcon;
