import { createSlice } from '@reduxjs/toolkit';

export const addLabelsSlice = createSlice({
  name: 'addLabels',
  initialState: { labels: [] },
  reducers: {
    setLabels: (state, action) => {
      state.labels = action.payload ?? [];
    },
    addLabel: (state, action) => {
      const label = action.payload;
      const index = state.labels.indexOf(label);
      if (index < 0) {
        state.labels.push(label);
      }
    },
    removeLabel: (state, action) => {
      const label = action.payload;
      const index = state.labels.indexOf(label);
      state.labels.splice(index, 1);
    },
    clearLabels: (state, action) => {
      state.labels = [];
    },
  },
});

export const { addLabel, setLabels, removeLabel, clearLabels } =
  addLabelsSlice.actions;
export default addLabelsSlice.reducer;
