import Message from './Message';
export const TYPES = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  DEFAULT: 'DEFAULT',
};
export const DEFAULT_DURATION = 3000;

export default class PageMessage extends Message {
  constructor({ text, type = TYPES.DEFAULT, duration = DEFAULT_DURATION }) {
    super(text);
    this._type = type;
    this._duration = duration;
  }
  get type() {
    return this._type;
  }
  get duration() {
    return this._duration;
  }
}
