import { bool, func, string, object } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateBack, faXmark } from '@fortawesome/free-solid-svg-icons';
import {
  faCircleCheck,
  faPenToSquare,
  faTrashCan,
} from '@fortawesome/free-regular-svg-icons';

const ActionButton = ({ onClick, onMouseOut, icon, label }) => {
  const buttonStyles = `h-full sm:px-6 tracking-wide sm:tracking-widest
  grow sm:grow-0`;
  const buttonContentStyles = `w-full h-full flex
  items-center justify-center sm:justify-end text-black uppercase`;
  return (
    <button className={buttonStyles} onClick={onClick}>
      <div className={buttonContentStyles}>
        <FontAwesomeIcon className='-z-50 pr-1' icon={icon} />
        {label}
      </div>
    </button>
  );
};

ActionButton.propTypes = {
  onClick: func,
  onMouseOut: func,
  icon: object,
  label: string,
};

const ActionsPanel = props => {
  const { isComplete, onClick, onMouseOut, onClickEdit, onClickDelete } = props;
  return (
    <div
      className='absolute z-20 left-0 top-0 w-full h-full
      flex justify-evenly sm:justify-end
      bg-gradient-to-l from-white via-white backdrop-blur-sm'
    >
      <ActionButton
        label='delete'
        onClick={onClickDelete}
        onMouseOut={onMouseOut}
        icon={faTrashCan}
      />
      <ActionButton
        label='edit'
        onClick={onClickEdit}
        onMouseOut={onMouseOut}
        icon={faPenToSquare}
      />
      <ActionButton
        label={isComplete ? 'reopen' : 'complete'}
        onClick={onClick}
        onMouseOut={onMouseOut}
        icon={isComplete ? faArrowRotateBack : faCircleCheck}
      />
      <div
        className='w-10 absolute -top-4 right-0 text-black h-8
        flex justify-center items-end'
      >
        <button onClick={onMouseOut} aria-label='close action panel'>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
    </div>
  );
};

ActionsPanel.propTypes = {
  isComplete: bool,
  onClick: func,
  onClickEdit: func,
  onClickDelete: func,
  onMouseOut: func,
};

export default ActionsPanel;
