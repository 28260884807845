import { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  cardStyles,
  categoryContainerClassName,
  mainContainerClassName,
} from '../../styles';
import CategoryContainer from './CategoryContainer';
import { getTasksByCategory } from '../../components/api/api';
import { handleCategoryResponse } from './dataHandlers';
import { displayPageError } from '../../components/utils/utils';
import { handleClick } from '../../components/Task/handlers';
import useNavContext from '../../hooks/useNavContext';

const AllTasks = () => {
  const taskCategories = useSelector(state => state.tasks);
  const { setNavHeader } = useContext(useNavContext());

  useEffect(() => {
    setNavHeader({ header: 'All Tasks' });
    getTasksByCategory()
      .then(handleCategoryResponse)
      .catch(error => {
        displayPageError(error);
      });
  }, []);

  if (!Object.keys(taskCategories).length) {
    return <h1>loading...</h1>;
  }

  const allCategories = [];
  for (const [categoryId, categoryProps] of Object.entries(taskCategories)) {
    allCategories.push(
      <CategoryContainer
        key={categoryId}
        categoryId={categoryId}
        displayInlineOptions={true}
        displayToggle={true}
        displayToggleExpanded={true}
        handleClick={handleClick}
        styleOverrides={{
          cardStyles,
          mainContainerClassName,
          categoryContainerClassName,
        }}
        {...categoryProps}
      />,
    );
  }

  return allCategories;
};

export default AllTasks;
