import { useDispatch, useSelector } from 'react-redux';
import useValidationState from '../../hooks/useValidationState';
import { setTaskName, clearTaskName } from '../store/taskNameSlice';

export const useSingleTaskInput = () => {
  const dispatch = useDispatch();
  const value = useSelector(state => state.taskName.name);
  const setValue = value => {
    dispatch(setTaskName(value));
  };

  const state = [value, setValue];
  const errorMessage = 'this field cannot be empty';
  const validator = () => value.length > 0;
  const { validationDetails, validate } = useValidationState({ validator });
  const SingleTaskInputValidationState = {
    ...validationDetails,
    displayError: true,
    message: errorMessage,
  };
  const getSingleTaskInputValue = () => state[0];
  const clearTaskNameInput = () => {
    dispatch(clearTaskName());
  };

  return {
    getSingleTaskInputValue,
    clearTaskNameInput,
    state,
    SingleTaskInputValidationState,
    validateSingleTaskInput: validate,
  };
};

export default useSingleTaskInput;
