import { isAFunction } from '../utils/utils';

export const getFilteredTasks = ({
  categoryId,
  component,
  displayOnly,
  handleClick,
  handleOnEdit,
  handleOnDelete,
  tasks,
}) => {
  const openTasks = [];
  const completedTasks = [];
  const Component = component;
  const filteredTasks = { completedTasks, openTasks };

  if (!tasks) {
    return filteredTasks;
  }

  for (const [taskId, taskData] of Object.entries(tasks)) {
    const currentTask = (
      <Component
        key={taskId}
        categoryId={categoryId}
        displayOnly={displayOnly}
        handleClick={getHandleClick(handleClick, { taskData })}
        handleOnEdit={handleOnEdit}
        handleOnDelete={handleOnDelete}
        {...taskData}
      />
    );
    if (taskData.status?.isComplete) {
      completedTasks.push(currentTask);
    } else {
      openTasks.push(currentTask);
    }
  }

  return filteredTasks;
};

export const getHandleClick = (clickHandler, data) => {
  if (isAFunction(clickHandler)) {
    return function() {
      return clickHandler(data);
    };
  }
  return null;
};
