import { array, string } from 'prop-types';
// import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addLabel, removeLabel } from '../store/addLabelsSlice';

const AddLabels = ({
  inputClassName,
  labelClassName,
  listClassName,
  value,
}) => {
  // const [labelVal, setLabelVal] = useState('');
  const [labelVal, setLabelVal] = value;
  const labels = useSelector(state => state.addLabel.labels);
  const dispatch = useDispatch();
  const isEnter = ({ key }) => key === 'Enter';
  const onChangehandler = e => setLabelVal(e.target.value);
  const displayClassName = `py-1 px-2 m-1 text-sm cursor-pointer rounded-lg 
    bg-sky-100 hover:bg-red-100 hover:line-through hover:italic`;

  return (
    <>
      <label className={labelClassName} htmlFor='task-lbl-no-autofill'>
        Labels
      </label>
      <input
        id='task-lbl-no-autofill'
        value={labelVal}
        onChange={onChangehandler}
        className={inputClassName}
        onKeyPress={e => {
          if (isEnter(e)) {
            e.preventDefault();
            dispatch(addLabel(labelVal.trim()));
            setLabelVal('');
          }
        }}
      ></input>
      <div className={`flex flex-wrap mt-2 text-sm ${listClassName}`}>
        {labels.map((label, key) => (
          <div
            key={key}
            className={displayClassName}
            onClick={() => {
              dispatch(removeLabel(label));
            }}
          >
            <span>{label}</span>
          </div>
        ))}
      </div>
    </>
  );
};

AddLabels.propTypes = {
  inputClassName: string,
  labelClassName: string,
  listClassName: string,
  value: array,
};

export default AddLabels;
