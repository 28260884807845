import { string, func } from 'prop-types';

import MultiLocationInput from '../MultiLocationInput/MultiLocationInput';
import AddLabels from '../AddLabels/AddLabels';
import useAddLabels from '../AddLabels/useAddLabels';
import { iconNames } from '../constants/iconMap';
import IconPicker from '../IconPicker/IconPicker';
import { useIconPicker } from '../IconPicker/useIconPicker';
import LabeledInput from '../LabeledInput/LabeledInput';
import useSingleTaskInput from '../SingleTaskInput/useSingleTaskInput';
import useMultiLocationInput from '../MultiLocationInput/useMultiLocationInput';
import { validateForm } from './utils';
import {
  mainContainerClassName,
  fieldContainerClassName,
  inputClassName,
  labelClassName,
  submitClassName,
} from './styles';
import { handleOnSubmit } from './handlers';

const EditTask = ({ id, onUpdate }) => {
  const {
    getSingleTaskInputValue,
    state: taskNameState,
    SingleTaskInputValidationState,
    validateSingleTaskInput,
  } = useSingleTaskInput();

  const {
    state: taskLocationState,
    getMultiLocationInputValue,
    getMultiLocationList,
  } = useMultiLocationInput();

  const { getIcon, state: taskIconState } = useIconPicker();

  const {
    state: taskLabelsState,
    getLabelsInputValue,
    getLabelsList,
  } = useAddLabels();

  const validators = [validateSingleTaskInput];

  return (
    <div className={mainContainerClassName}>
      <form
        onSubmit={e => {
          e.preventDefault();
          if (!validateForm(validators)) {
            return;
          }

          const taskData = {
            id,
            icon: getIcon(),
            title: getSingleTaskInputValue().trim(),
            titleList: [],
            location: getMultiLocationInputValue().trim(),
            locationList: getMultiLocationList(),
            label: getLabelsInputValue(),
            labels: getLabelsList(),
          };
          handleOnSubmit({ taskData, onUpdate });
        }}
      >
        <div className={fieldContainerClassName}>
          <LabeledInput
            value={taskNameState}
            validationState={SingleTaskInputValidationState}
            label='Task Name'
            inputClassName={inputClassName}
            labelClassName={labelClassName}
          />
        </div>
        <div className={fieldContainerClassName}>
          <MultiLocationInput
            value={taskLocationState}
            inputClassName={inputClassName}
            labelClassName={labelClassName}
          />
        </div>
        <div className={fieldContainerClassName}>
          <IconPicker
            value={taskIconState}
            icons={iconNames}
            labelClassName={labelClassName}
          />
        </div>
        <div className={fieldContainerClassName}>
          <AddLabels
            value={taskLabelsState}
            inputClassName={inputClassName}
            labelClassName={labelClassName}
          />
        </div>
        <div>
          <input
            value='Update Task'
            type='submit'
            className={submitClassName}
          />
        </div>
      </form>
    </div>
  );
};

EditTask.propTypes = { id: string, onUpdate: func };

export default EditTask;
