import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { func, string } from 'prop-types';
import { removeUser } from '../store/userSlice';
import { addLoginMessage } from '../store/messagesSlice';
import { logoutUser } from '../api/api';

const Logout = ({ className, onClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div
      className={'cursor-pointer ' + className}
      onClick={e => {
        e.preventDefault();
        logoutUser()
          .then(data => {
            dispatch(removeUser());
            onClick(data);
            return data.message;
          })
          .then(message => {
            dispatch(addLoginMessage({ message }));
            navigate('/login');
          });
      }}
    >
      logout
    </div>
  );
};

Logout.propTypes = { className: string, onClick: func };

export default Logout;
