import { useState } from 'react';
import { func, string, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { twMerge } from 'tailwind-merge';
import { proofFn } from '../../utils/utils';

const getToggleArrow = showDownArrow => {
  let ariaLabel = 'right-arrow';
  let icon = faChevronRight;

  if (showDownArrow) {
    ariaLabel = 'down-arrow';
    icon = faChevronDown;
  }

  return (
    <FontAwesomeIcon aria-label={ariaLabel} icon={icon} className={`w-5`} />
  );
};

const Inline = ({ handler, styleOverrides, text, showToggleArrow }) => {
  const [showDownArrow, setShowDownArrow] = useState(false);
  const classes = twMerge(
    'cursor-pointer uppercase text-xs font-bold text-blue-500',
    styleOverrides,
  );

  return (
    <div
      onClick={e => {
        setShowDownArrow(!showDownArrow);
        proofFn(handler)(e);
      }}
      className={classes}
    >
      {text}
      {showToggleArrow && getToggleArrow(showDownArrow)}
    </div>
  );
};

Inline.propTypes = {
  handler: func,
  styleOverrides: string,
  text: string,
  showToggleArrow: bool,
};

export default Inline;
