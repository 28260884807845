// TODO: this catalog should be kept in the DB
import {
  faBookmark,
  faTrashCan,
  faStar,
  faBell,
  faCalendar,
  faMessage,
  faLightbulb,
} from '@fortawesome/free-regular-svg-icons';

export const iconMap = {
  bookmark: faBookmark,
  trash: faTrashCan,
  star: faStar,
  bell: faBell,
  calendar: faCalendar,
  message: faMessage,
  idea: faLightbulb,
};

export const iconNames = Object.keys(iconMap);
export const DEFAULT_ICON_NAME = 'star';
