import { createSlice } from '@reduxjs/toolkit';

const initialState = {};
export const errorMessageSlice = createSlice({
  name: 'errorMessages',
  initialState,
  reducers: {
    addPageLevelError: (state, action) => {
      state.pageLevelError = { ...action.payload };
    },
    removePageLevelError: (state, action) => {
      state.pageLevelError = {};
    },
    clearErrorMessages: () => initialState,
  },
});

export const { addPageLevelError, removePageLevelError, clearErrorMessages } =
  errorMessageSlice.actions;
export default errorMessageSlice.reducer;
